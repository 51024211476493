<template>
  <div class="modal fade" id="confirmSwitch" tabindex="-1" role="dialog" aria-hidden="true" ref="vuemodal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content p-2">
        <div class="modal-header">
          <label class="modal-title" id="exampleModalLongTitle" v-if="account && account.status === 'blocked'">こちらの管理者をブロック解除してよろしいですが？</label>
          <label class="modal-title" id="exampleModalLongTitle" v-if="account && account.status === 'active'">こちらの管理者をブロックしてよろしいですが？</label>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped">
            <thead>
              <th class="mw-150">管理者ID</th>
              <th class="mw-150">管理者メール</th>
              <th class="mw-150">管理者名</th>
            </thead>
            <tbody>
              <tr>
                <td v-if="account">{{ account.id }}</td>
                <td v-if="account">{{ account.email }}</td>
                <td v-if="account">{{ account.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <a class="btn btn-info" data-dismiss="modal" v-if="account && account.status === 'active'" @click="onSubmit()">ブロックする</a>
          <a class="btn btn-info" data-dismiss="modal" v-if="account && account.status === 'blocked'" @click="onSubmit()">ブロックを解除する</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: ['accounts'],
  data() {
    return {
      adminUrl: process.env.VUE_APP_ADMIN_URL,
      account: null
    };
  },

  mounted() {
    $(this.$refs.vuemodal).on('show.bs.modal', this.shownModal);
  },

  methods: {
    ...mapActions('account', ['updateAccount']),
    shownModal() {
      if ($('#inputValue').val()) {
        const currAdminId = $('#inputValue').val();
        this.account = this.accounts.find(e => Number(e.id) === Number(currAdminId));
      }
    },

    async onSubmit() {
      const data = {
        id: this.account.id,
        status: (this.account.status === 'blocked') ? 'active' : 'blocked'
      };
      await this.updateAccount(data);
      window.toastr.success('管理者の状況を変更しました。');
      setTimeout(() => {
        location.reload();
      }, 1500);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-modal-delete {
  background: #fb7c7c;
}

.btn-modal  {
  color: #fff;
  padding: 10px 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 200px;
  letter-spacing: .1em;
  display: block;
  width: 100%;
}

.modal-title {
  font-size: 16px;
}
</style>
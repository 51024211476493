<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">メニュー新規作成</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <div class="card-body row">
            <div class="offset-xl-3 col-xl-6">
              <div class="form-group row">
                <label class="col-3">メニュー名<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="メニュー名" rules="required|max:255" v-slot="{ errors }">
                  <input type="text" class="form-control" name="food_master[name]" placeholder="メニュー名を入力してください" v-model="foodMasterForm.name" @change="getValidateUnique()">
                  <span class="error-explanation" v-if="nameUnique"> メニュー名は既存しています。 </span>
                  <span class="error-explanation" v-else>{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3">単位<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="単位" rules="required|max:32" v-slot="{ errors }">
                  <input type="text" class="form-control" name="food_master[unit]" placeholder="単位を入力してください" v-model="foodMasterForm.unit">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3">ボリューム<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="ボリューム" rules="required" v-slot="{ errors }">
                  <input type="number" class="form-control" step="0.1" name="food_master[volume]" placeholder="ボリュームを入力してください" v-model="foodMasterForm.volume">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3">カロ<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="カロ" rules="required" v-slot="{ errors }">
                  <input type="number" step="0.01" class="form-control" name="food_master[calo]" placeholder="カロを入力してください" v-model="foodMasterForm.calo">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex">
            <div class="btn btn-info" @click="backToList()">一覧へ戻る</div>
            <submit-button class="m-auto" object="メニュー" action="作成" :submitted="submitted"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import { mapActions } from 'vuex';

export default {
  props: ['food_master'],

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      foodMasterForm: {
        name: null,
        unit: null,
        volume: null,
        calo: null
      },
      selectableCheckPoints: [],
      selectedCheckPoint: null,
      submitted: false,
      nameUnique: false
    };
  },

  methods: {
    ...mapActions('foodMaster', ['validateUnique']),
    async onSubmit(e) {
      if (this.nameUnique) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },
    async getValidateUnique() {
      const data = { name: this.foodMasterForm.name, food_master_id: this.foodMasterForm.id };
      const res = await this.validateUnique(data);
      res && !res.data.unique ? this.nameUnique = true : this.nameUnique = false;
    },
    getAction() {
      return `${this.adminRootUrl}/food_masters`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/food_masters`;
    }
  }
};
</script>

<style>
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">ポイント付与ルール編集</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="rule[id]" :value="ruleForm.id">
          <div class="card-body row">
            <div  class="offset-xl-3 col-xl-6">
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">ルールID</label>
                <div class="col-xl-8 col-md-9">
                  <input type="text" class="form-control" v-model="ruleForm.id" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">ルール名</label>
                <div class="col-xl-8 col-md-9">
                  <input type="text" class="form-control" v-model="ruleForm.name" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">ポイント<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                  <ValidationProvider name="ポイント" rules="required" v-slot="{ errors }">
                    <input type="number" name="rule[point]" min="0" class="form-control" v-model="ruleForm.point" :disabled="ruleForm.type == 'advanced'">
                    <span class="error-explanation">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Enabled / Disabled -->
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">無効 / 有効</label>
                <div class="col-xl-8 col-md-9">
                  <div class="btn-toggle">
                    <label class="switch" for="enabledCheck">
                      <input type="checkbox" class="form-check-input" id="enabledCheck" name="rule[enabled]" v-model="ruleForm.enabled">
                      <span class="slider round"></span>
                    </label>
                  </div>
                   <input type="hidden" class="form-check-input" id="enabledCheck" name="rule[enabled]" v-model="ruleForm.enabled">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-center d-flex">
            <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
            <submit-button class="mr-auto" object="ポイント付与ルール" action="更新" :submitted="submitted"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['rule'],

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      ruleForm: {
        id: null,
        name: null,
        point: 0,
        settings: null,
        enabled: false
      },
      submitted: false
    };
  },

  created() {
    Object.assign(this.ruleForm, this.rule);
  },

  methods: {
    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/rules/${this.rule.id}`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/rules`;
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
import { API } from '../api';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {

  async updateEnabled(context, query) {
    try {
      return await API.put('/announcements/' + query.id, query);
    } catch (error) {
      return error;
    }
  }

};
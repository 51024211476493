class MapHelper {
  static normalizeTrackPoint(trackPoint) {
    try {
      trackPoint.lat = parseFloat(trackPoint.lat);
      trackPoint.lng = parseFloat(trackPoint.lng);
      return trackPoint;
    } catch (e) {
      console.log(e);
    }
  }

  static normalizePos(lat, lng) {
    var side = (Math.cos(lat * Math.PI / 180) > 0) ? 0 : 180;

    var latitude = Math.asin(Math.sin(lat * Math.PI / 180)) * 180 / Math.PI;
    var longitude = Math.atan(Math.tan((lng + side) * Math.PI / 360)) * 360 / Math.PI;

    return [latitude, longitude];
  }

  static pointInPolygonNested(point, vs, start, end) {
    var x = Number(point[0]); var y = Number(point[1]);
    var inside = false;
    if (start === undefined) start = 0;
    if (end === undefined) end = vs.length;
    var len = end - start;
    for (var i = 0, j = len - 1; i < len; j = i++) {
      var xi = Number(vs[i + start][0]); var yi = Number(vs[i + start][1]);
      var xj = Number(vs[j + start][0]); var yj = Number(vs[j + start][1]);
      var intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  }

  static pointInCircle(point, center, radius) {
    var R = 6371; // Radius of the earth in km
    var lat1 = Number(point[0]);
    var lat2 = Number(center[0]);
    var lon1 = Number(point[1]);
    var lon2 = Number(center[1]);
    radius = Number(radius);
    const deg2rad = (deg) => {
      return deg * (Math.PI / 180);
    };
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d < (Number(radius) / 1000);
  }
}
export default MapHelper;
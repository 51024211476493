<template>
  <div>
    <div id="modalBonusPoint" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="vuemodal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <label>ユーザー： {{this.userId}} に付与するポイント数を入力してください</label>
            <button type="button" class="close" data-dismiss="modal">×</button>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
              <input type="hidden" name="authenticity_token" :value="csrfToken">
              <div class="modal-body">
                <div class="row">
                  <label class="col-4">ポイント数</label>
                  <div class="col-8">
                    <input type="number" class="form-control" v-model="point" name="point">
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">キャンセル</button>
                <button type="button" class="btn btn-info" @click="onSubmit()">確定</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/utils/util.js';

export default {
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      point: '',
      userId: '',
      submitted: false
    };
  },

  mounted() {
    $(this.$refs.vuemodal).on('show.bs.modal', this.shownModal);
  },

  methods: {
    shownModal() {
      if ($('#inputValue').val()) {
        this.userId = $('#inputValue').val();
      }
    },

    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },

    getAction() {
      return `${this.adminRootUrl}/users/${this.userId}/bonus_point`;
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
<template>
  <div class="modal fade" id="iconPickerModal" tabindex="-1" role="dialog" aria-hidden="true" ref="vuemodal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content p-2">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">アイコンを選ぶ</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-flex">
          <div class="row">
            <template v-for="(icon, index) in icons">
              <div class="col-md-2" role="button" :key="index" @click="setIcon(icon)" data-dismiss="modal">
                <div class="card border-secondary m-2">
                  <div class="card-body text-secondary text-center">
                    <img :src="icon.image_url" class="icon-image">
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['icons', 'icon'],
  data() {
    return {
    };
  },
  methods: {
    setIcon(icon) {
      this.$emit('update:icon', icon);
    }
  }
};
</script>

<style lang="scss" scoped>
  .icon-image{
    width: 100px;
    object-fit: cover;
    height: 100px;
    border-radius: 5px;
  }
</style>

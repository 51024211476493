<template>
  <div class="modal fade" id="locationPickerModal" tabindex="-1" role="dialog" aria-hidden="true" ref="vuemodal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content p-2">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">場所を選ぶ</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-flex flex-column">
          <div class="d-flex align-center mb-2 w-50">
            <input
              type="text"
              class="form-control mr-2"
              name="check_point[lat]"
              v-model.number="newLat"
              @input="updateMarker"
            >
            -
            <input
              type="text"
              class="form-control ml-2"
              name="check_point[lng]"
              v-model.number="newLng"
              @input="updateMarker"
            >
          </div>
          <div class="map-container">
            <l-map
              ref="map"
              :zoom="zoom"
              min-zoom="4"
              :center="center"
              @update:zoom="zoomUpdated"
              @update:center="centerUpdated"
              @update:bounds="boundsUpdated"
              @ready="onMapReady()"
            >
              <l-tile-layer :url="url" no-wrap="true"></l-tile-layer>
            </l-map>
          </div>
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">キャンセル</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="setLocation()">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet';
import L from 'leaflet';

export default {
  components: {
    LMap,
    LTileLayer
  },
  props: ['lat', 'lng'],
  data() {
    return {
      map: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: L.latLng(35.69, 139.70),
      bounds: null,
      accuracy: 6,
      marker: null,
      newLat: null,
      newLng: null
    };
  },
  created() {
    if (this.lat && this.lng) {
      this.center = L.latLng(this.lat, this.lng);
      this.newLat = this.lat;
      this.newLng = this.lng;
    };
  },

  mounted() {
    $(this.$refs.vuemodal).on('show.bs.modal', this.onModalShow);
    $(this.$refs.vuemodal).on('hide.bs.modal', this.onModalHide);
  },

  watch: {
    center(val) {
      this.newLat = val.lat;
      this.newLng = val.lng;
    }
  },

  methods: {
    onModalShow() {
      if (this.lat && this.lng) {
        this.center = L.latLng(this.lat, this.lng);
        this.newLat = this.lat;
        this.newLng = this.lng;
      };
    },

    onModalHide() {
      this.center = L.latLng(35.69, 139.70);
      this.newLat = null;
      this.newLng = null;
    },

    onMapReady() {
      this.initMap();
    },

    initMap() {
      this.map = this.$refs.map.mapObject;
      const icon = L.divIcon({
        className: 'map-pin-icon',
        html: "<i class='fas fa-thumbtack text-danger' style='font-size: 40px !important;'></i>",
        iconSize: [42, 42],
        iconAnchor: [15, 42]
      });
      this.marker = L.marker([this.center.lat, this.center.lng], { icon: icon }).addTo(this.map);

      const _this = this;
      $('#locationPickerModal').on('shown.bs.modal', function() {
        _this.map.invalidateSize();
      });
    },

    zoomUpdated(zoom) {
      this.zoom = zoom;
    },

    centerUpdated(center) {
      this.center = center;
      this.center && this.marker.setLatLng([this.center.lat, this.center.lng]);
    },

    boundsUpdated(bounds) {
      this.bounds = bounds;
    },

    setLocation() {
      this.$emit('update:lat', this.center.lat);
      this.$emit('update:lng', this.center.lng);
      this.$emit('onUpdate', true);
      this.onModalHide();
    },

    updateMarker: _.debounce(function(e) {
      this.center = L.latLng(this.newLat, this.newLng);
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  height: 60vh;
}
</style>

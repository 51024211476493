<template>
  <div>
    <div
      class="modal fade"
      id="modalCsvFoodMaster"
      ref="vueModal"
      tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      :data-backdrop="'static'"
    >
      <div class="modal-dialog modal-xl" role="document">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="modal-content">
            <div class="modal-header">
              <h5> 食事マスターのインポート </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()" :disabled="['loading_preview', 'importing'].includes(status)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" :class="['preview', 'preview_error', 'importing', 'imported'].includes(status) ? 'modal-scroll-x' : ''">
              <div v-if="importResult" class="text-center imported-message">
                インポート結果：
                <span>{{ importResult.created_count }}作成</span>
                <span class="ml-5">{{ importResult.updated_count }}更新</span>
                <span class="ml-5 failed-count">{{ importResult.failed_count }}エラー</span>
              </div>
              <div>
                <b-form-file
                  ref="csvFile"
                  browse-text="ファイルを選択"
                  accept="text/csv"
                  placeholder="CSVファイルを選択してください"
                  drop-placeholder="ここにファイルをドロップ。。。"
                  @change="onUploadFile"
                  v-if="!importResult"
                  v-model="dataImport"
                  :disabled="['loading_preview', 'importing'].includes(status)"
                ></b-form-file>
              </div>
              <div>
                <div class="mt-2">
                  <template v-for="(error, index) in errors">
                    <div :key="index" class="error-explanation"><template v-if="error.line">{{ error.line }}行目にエラーがありました:</template> {{ error.message }}</div>
                  </template>
                </div>
                <div v-if="!dataChanged && ['preview'].includes(status)" class="text-warning mt-4">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span>データは変更されていません。</span>
                </div>
                <div class="table-responsive" v-show="['preview', 'preview_error', 'importing', 'imported'].includes(status)">
                  <table class="table table-bordered table-striped mt-4">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>食事名</th>
                        <th>分量</th>
                        <th>単位</th>
                        <th>カロ</th>
                        <th>ステータス</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(foodMaster, index) in foodMasters">
                        <tr :key="index" :class="errors.some(error => error.line === index + 1) ? 'error-explanation' : ''">
                          <th class="font-weight-normal"> {{ index + 1 }} </th>
                          <th class="font-weight-normal"> {{ foodMaster.name }} </th>
                          <th class="font-weight-normal"> {{ foodMaster.volume }} </th>
                          <th class="font-weight-normal"> {{ foodMaster.unit }} </th>
                          <th class="font-weight-normal"> {{ foodMaster.calo }} </th>
                          <th>
                            <span v-if="errors.some(error => error.line === index + 1) " class="badge badge-danger">エラー</span>
                            <div v-else v-html="getStatus(foodMaster)"></div>
                          </th>
                        </tr>
                      </template>
                      <tr>
                        <td>...</td>
                        <td colspan="5">......</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-show="status === 'loading_preview'" class="text-center p-5">
                <loading-spinner></loading-spinner>
              </div>
            </div>
            <div class="modal-footer text-center d-flex">
              <div class="m-auto">
                <button
                  class="btn btn-info mr-2"
                  :disabled="['preview_error', 'file_corrupted'].includes(status) || !status"
                  @click="handleSubmit(onPreviewCsv)"
                  v-if="['preview_error', 'picked_file', 'file_corrupted'].includes(status) || !status"
                >
                  <span v-if="status === 'loading_preview'">
                    <b-spinner small></b-spinner>
                  </span>
                  プレビュー
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  @click="['imported', 'importing'].includes(status) || !dataChanged ? '' : importFoodMaster()"
                  :disabled="['imported', 'importing'].includes(status) || !dataChanged || ($refs.csvFile && $refs.csvFile.files.length === 0)"
                  v-if="['preview', 'importing'].includes(status)"
                >
                  <span v-if="status === 'importing'">
                    <b-spinner small></b-spinner>
                  </span>
                  インポートする
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      foodMasters: [],
      errors: [],
      importResult: null,
      dataImport: null,
      status: null,
      dataChanged: false
    };
  },

  mounted() {
    $(this.$refs.vueModal).on('hidden.bs.modal', this.closeModal);
  },

  methods: {
    ...mapActions('foodMaster', ['previewCsv', 'importFoodMasters']),

    onUploadFile() {
      this.foodMasters = [];
      this.errors = [];
      this.status = 'picked_file';
      this.dataChanged = false;
    },

    async onPreviewCsv(event) {
      this.foodMasters = [];
      this.errors = [];
      this.status = 'loading_preview';
      const response = await this.previewCsv(this.dataImport);
      this.$refs.csvFile.$refs.input.value = '';
      this.foodMasters = response.data.rows;
      this.errors = response.data.errors;
      this.dataChanged = response.data.data_changed;
      if (this.errors.length > 0 && this.errors[0].line) {
        this.status = 'preview_error';
      } else if (this.errors.length > 0 && !this.errors[0].line) {
        this.status = 'file_corrupted';
      } else {
        this.status = 'preview';
      }
    },

    async importFoodMaster(event) {
      this.status = 'importing';
      const response = await this.importFoodMasters(this.dataImport);
      this.importResult = response.data;
      this.status = 'imported';
      window.toastr.success(`作成：${this.importResult.created_count} 更新：${this.importResult.updated_count} エラー：${this.importResult.failed_count}`);
    },

    getStatus(foodMaster) {
      if (foodMaster.create) {
        return '<span class="badge badge-success">作成</span>';
      }

      if (foodMaster.update) {
        return '<span class="badge badge-info">更新</span>';
      }

      return '<span class="badge badge-secondary">変更なし</span>';
    },

    closeModal() {
      window.location.href = `${this.adminRootUrl}/food_masters`;
    }
  }
};
</script>
<style lang="scss" scoped>
  .error-explanation {
    color: #a94442;
    font-size: 14px;
    font-weight: 700;
  }

  .imported-message {
    font-size: 18px;
    font-weight: 700;
    color: #28a745;
    .failed-count {
      color: #a94442;
    }
  }

  .modal-scroll-x {
    height: 75vh;
    overflow-x: auto;
  }
</style>
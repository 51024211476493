<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title" v-if="nendo">年度更新</h3>
          <h3 class="card-title header-title" v-else>年度新規作成</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" v-if="nendo"/>
          <input type="hidden" name="nendo[id]" :value="nendoForm.id">
          <div class="card-body offset-xl-2 col-xl-8">
            <!-- 年度 -->
            <div class="form-group row">
              <label class="col-4">年度<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="年度" rules="required" v-slot="{ errors }">
                <input type="number" class="form-control" name="nendo[year]" placeholder="年度を入力してください" v-model="nendoForm.year" :disabled="nendoForm.id">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- 年度開始日時 -->
            <div class="form-group row">
              <label class="col-4">年度開始日時<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="年度開始日時" rules="required" v-slot="{ errors }">
                <datetime
                  v-model="nendoForm.nendo_start"
                  input-class="form-control"
                  type="date"
                  hidden-name="nendo[nendo_start]"
                  :phrases="{ok: '確定', cancel: '閉じる'}"
                  placeholder="日付を選択してください"
                  :max-datetime="nendoForm.nendo_end"
                  zone="Asia/Tokyo"
                  value-zone="Asia/Tokyo"
                  :disabled="nendoForm.id"
                ></datetime>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- 年度終了日時 -->
            <div class="form-group row">
              <label class="col-4">年度終了日時<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="年度終了日時" rules="required" v-slot="{ errors }">
                <datetime
                  v-model="nendoForm.nendo_end"
                  input-class="form-control"
                  type="date"
                  hidden-name="nendo[nendo_end]"
                  :phrases="{ok: '確定', cancel: '閉じる'}"
                  placeholder="日付を選択してください"
                  :min-datetime="nendoForm.nendo_start"
                  zone="Asia/Tokyo"
                  value-zone="Asia/Tokyo"
                  :disabled="nendoForm.id"
                ></datetime>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- ポイント交換・抽選開始日時 -->
            <div class="form-group row">
              <label class="col-4">ポイント交換・抽選開始日時<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="ポイント交換・抽選開始日時" rules="required" v-slot="{ errors }">
                <datetime
                  v-model="nendoForm.exchange_start"
                  input-class="form-control"
                  type="date"
                  hidden-name="nendo[exchange_start]"
                  :phrases="{ok: '確定', cancel: '閉じる'}"
                  placeholder="日付を選択してください"
                  :min-datetime="nendoForm.nendo_end"
                  zone="Asia/Tokyo"
                  value-zone="Asia/Tokyo"
                ></datetime>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- ポイント交換終了日時 -->
            <div class="form-group row">
              <label class="col-4">ポイント交換・抽選終了日時<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="ポイント交換終了日時" rules="required" v-slot="{ errors }">
                <datetime
                  v-model="nendoForm.exchange_end"
                  input-class="form-control"
                  type="date"
                  hidden-name="nendo[exchange_end]"
                  :phrases="{ok: '確定', cancel: '閉じる'}"
                  placeholder="日付を選択してください"
                  :min-datetime="nendoForm.exchange_start"
                  zone="Asia/Tokyo"
                  value-zone="Asia/Tokyo"
                ></datetime>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <submit-button object="年度" :action="nendo ? '更新' : '作成'" :submitted="submitted"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';
import { Datetime } from 'vue-datetime';

export default {
  props: ['nendo'],

  components: {
    Datetime
  },

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      nendoForm: {
        year: null,
        nendo_start: null,
        nendo_end: null,
        exchange_start: null,
        exchange_end: null
      },
      submitted: false
    };
  },
  watch: {
    nendoForm: {
      deep: true,
      handler() {
        if (this.nendoForm.end_time < this.nendoForm.start_time) {
          this.nendoForm.end_time = this.nendoForm.start_time;
        }
      }
    },

    'nendoForm.exchange_start': function(newDate, oldDate) {
      if (oldDate && this.nendoForm.exchange_end < newDate) {
        const date = new Date(newDate);
        const newExchangeEnd = date.setMonth(date.getMonth() + 1);
        this.nendoForm.exchange_end = moment(newExchangeEnd).format('YYYY-MM-DD');
      }
    }
  },
  created() {
    if (this.nendo) {
      Object.assign(this.nendoForm, this.nendo);
      this.nendoForm.nendo_start = moment.tz(this.nendo.nendo_start, 'Asia/Tokyo').format();
      this.nendoForm.nendo_end = moment.tz(this.nendo.nendo_end, 'Asia/Tokyo').format();
      this.nendoForm.exchange_start = moment.tz(this.nendo.exchange_start, 'Asia/Tokyo').format();
      this.nendoForm.exchange_end = moment.tz(this.nendo.exchange_end, 'Asia/Tokyo').format();
    } else {
      // Set default value
      this.nendoForm.year = moment().year();
      this.nendoForm.nendo_start = moment().date(1).month(3).format();
      this.nendoForm.nendo_end = moment().date(31).month(2).format();
      this.nendoForm.exchange_start = moment().date(1).month(4).format();
      this.nendoForm.exchange_end = moment().date(1).month(5).format();
    }
  },

  methods: {
    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return this.nendo ? `${this.adminRootUrl}/nendos/${this.nendo.id}` : `${this.adminRootUrl}/nendos`;
    }
  }
};
</script>

<style>

</style>
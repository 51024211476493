<template>
  <div class="btn-toggle">
    <label class="switch" :for="`enabledCheck${rule.id}`">
      <template v-if=ruleData.enabled>
        <input type="checkbox" :id="`enabledCheck${rule.id}`" v-model="ruleData.enabled" @input="changeEnabled" checked>
      </template>
      <template v-else>
        <input type="checkbox" :id="`enabledCheck${rule.id}`" v-model="ruleData.enabled" @input="changeEnabled">
      </template>
      <span class="slider round"></span>
    </label>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props: ['rule', 'disable'],
  data() {
    return {
      ruleData: _.cloneDeep(this.rule)
    };
  },
  methods: {
    ...mapActions('rule', ['updateEnabled']),
    async changeEnabled() {
      const data = {
        id: this.ruleData.id,
        enabled: !this.ruleData.enabled
      };
      await this.updateEnabled(data);
      window.toastr.success('ポイント付与ルールの設定は変更しました。');
    }
  }
};
</script>

import { API } from '../api';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {

  async previewCsv(context, file) {
    const formData = new FormData();
    formData.append('csv', file);
    try {
      return await API.post('/calo_target_masters/import_preview', formData);
    } catch (error) {
      console.log(error);
    }
  },

  async importCaloTargetMasters(context, file) {
    const formData = new FormData();
    formData.append('csv', file);

    try {
      return await API.post('/calo_target_masters/import', formData);
    } catch (error) {
      console.log(error);
    }
  }
};
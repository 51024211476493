var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"form",attrs:{"action":_vm.getAction(),"method":"post","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('input',{attrs:{"type":"hidden","name":"authenticity_token"},domProps:{"value":_vm.csrfToken}}),_vm._v(" "),_c('div',{staticClass:"offset-xl-2 col-xl-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("表示順"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":"表示順","rules":"required|min_value:0|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.sort_order),expression:"rewardForm.sort_order"}],staticClass:"form-control",attrs:{"type":"number","name":"reward[sort_order]"},domProps:{"value":(_vm.rewardForm.sort_order)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "sort_order", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("年度"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":"年度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.nendoOptions,"label":"year","placeholder":"年度を選択してください","append-to-body":""},on:{"input":_vm.onSelectNendo},model:{value:(_vm.selectedNendo),callback:function ($$v) {_vm.selectedNendo=$$v},expression:"selectedNendo"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.nendo_id),expression:"rewardForm.nendo_id"}],attrs:{"type":"hidden","name":"reward[nendo_id]"},domProps:{"value":(_vm.rewardForm.nendo_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "nendo_id", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("カテゴリー"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":"カテゴリー","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categoryOptions,"label":"title","placeholder":"カテゴリーを選択してください","append-to-body":""},on:{"input":_vm.onSelectCategory},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.type),expression:"rewardForm.type"}],attrs:{"type":"hidden","name":"reward[type]"},domProps:{"value":(_vm.rewardForm.type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "type", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("物品名称"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":"物品名","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.name),expression:"rewardForm.name"}],staticClass:"form-control",attrs:{"rows":"3","name":"reward[name]","placeholder":"物品名を入力してください"},domProps:{"value":(_vm.rewardForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("説明")]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.description),expression:"rewardForm.description"}],staticClass:"form-control",attrs:{"rows":"8","name":"reward[description]","placeholder":"説明を入力してください"},domProps:{"value":(_vm.rewardForm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "description", $event.target.value)}}})])]),_vm._v(" "),(_vm.rewardForm.type === 'commodity')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("ポイント数"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":"ポイント数","rules":"required|min_value:1|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.point),expression:"rewardForm.point"}],staticClass:"form-control",attrs:{"type":"number","name":"reward[point]","placeholder":"ポイント数を入力してください"},domProps:{"value":(_vm.rewardForm.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "point", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_vm._v(" "),(_vm.rewardForm.type === 'lottery')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-3"},[_vm._v("当選人数"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('ValidationProvider',{attrs:{"name":"当選人数","rules":"required|min_value:1|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rewardForm.capacity),expression:"rewardForm.capacity"}],staticClass:"form-control",attrs:{"type":"number","name":"reward[capacity]","placeholder":"当選人数を入力してください"},domProps:{"value":(_vm.rewardForm.capacity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rewardForm, "capacity", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_vm._v(" "),_c('image-picker',{attrs:{"imageUrl":_vm.rewardForm.image_url,"imageType":_vm.isValidImageType,"name":'reward[image]',"classLabel":'col-3',"classInput":'col-9 d-flex',"classErrors":'offset-3 col-9',"classImage":'reward-image',"veeName":'物品画像',"veeRules":'required',"recommendation":"画像サイズは縦450px×横900px以上（縦横比1:2）を推奨"},on:{"url":function($event){_vm.rewardForm.image_url = $event},"type":function($event){_vm.isValidImageType = $event}}})],1),_vm._v(" "),_c('div',{staticClass:"card-footer text-center d-flex"},[_c('div',{staticClass:"btn btn-info mr-auto",on:{"click":function($event){return _vm.backToList()}}},[_vm._v("一覧へ戻る")]),_vm._v(" "),_c('submit-button',{staticClass:"mr-auto",attrs:{"object":"物品","action":"作成","submitted":_vm.submitted,"disabled":invalid || _vm.isValidImageType}})],1)])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-name header-name"},[_vm._v("物品新規作成")])])}]

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">新規管理者の登録</h3>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post" enctype="multipart/form-data">
            <input type="hidden" name="authenticity_token" :value="csrfToken">
            <div class="card-body offset-lg-2 col-lg-8">
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">メールアドレス<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="メールアドレス" rules="required|max:255|email" v-slot="{ errors }">
                  <input type="text" class="form-control" name="account[email]" v-model="accountForm.email" @change="getValidateUnique()" >
                  <span class="error-explanation" v-if="!isEmailUnique"> メールアドレスは既存しています。 </span>
                  <span class="error-explanation" v-else>{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">氏名<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="氏名" rules="required|max:255" v-slot="{ errors }">
                  <input type="text" class="form-control" name="account[name]" v-model="accountForm.name">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">パスワード<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="パスワード" rules="required|min:8|max:128" v-slot="{ errors }" vid="password">
                  <input type="text" class="form-control" name="account[password]" v-model="accountForm.password">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">パスワード（確認用）<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="パスワード（確認用）" rules="required|min:8|max:128|confirmed:password" v-slot="{ errors }">
                  <input type="text" class="form-control" name="account[password_confirmation]" v-model="accountForm.passwordConfirmation">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- Start: upload image -->
              <image-picker
                :imageUrl = "accountForm.avatar_url"
                :imageType="isValidImageType"
                :name = "'account[avatar]'"
                :classLabel = "'col-xl-4 col-md-3'"
                :classInput = "'col-xl-8 col-md-9 d-flex'"
                :classErrors = "'offset-xl-4 col-xl-8 offset-md-3 col-md-9 mt-1'"
                :classImage = "'user-image'"
                label="アバター"
                @url="accountForm.avatar_url = $event"
                @type="isValidImageType = $event"
              ></image-picker>
              <!-- End: upload image -->
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">無効 / 有効</label>
                <div class="col-xl-8 col-md-9">
                  <div class="btn-toggle">
                    <label class="switch" for="enabledCheck">
                      <input type="checkbox" id="enabledCheck" v-model="enabled" name="account[status]" @change="onActive">
                      <span class="slider round"></span>
                    </label>
                    <input type="hidden" name="account[status]" v-model="accountForm.status">
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex">
              <div class="btn btn-info" @click="backToList()">一覧へ戻る</div>
              <submit-button class="m-auto" object="管理者" action="登録" :submitted="submitted" :disabled="invalid || !isEmailUnique || isValidImageType"></submit-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Util from '@/utils/util.js';

export default {
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      accountForm: {
        name: null,
        email: null,
        avatar_url: null,
        status: 'active',
        password: null,
        passwordConfirmation: null
      },
      submitted: false,
      isEmailUnique: true,
      enabled: true,
      isValidImageType: false
    };
  },

  methods: {
    ...mapActions('account', ['validateUnique']),

    async onSubmit(e) {
      if (!this.isEmailUnique || this.isValidImageType) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/accounts`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/accounts`;
    },
    async getValidateUnique() {
      const data = { email: this.accountForm.email, account_id: this.accountForm.id };
      const res = await this.validateUnique(data);
      res && res.data.unique ? this.isEmailUnique = true : this.isEmailUnique = false;
    },
    onActive() {
      this.enabled ? this.accountForm.status = 'active' : this.accountForm.status = 'blocked';
    }
  }
};
</script>
<style lang="scss" scoped>
</style>

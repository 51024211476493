import { API } from '../api';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {

  async createCoupon(context, query) {
    try {
      return await API.post('/coupons', query);
    } catch (error) {
      return error;
    }
  },

  async updateCoupon(context, query) {
    try {
      return await API.put('/coupons/' + query[1], query[0]);
    } catch (error) {
      return error;
    }
  }

};
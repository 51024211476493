<template>
  <div class="row justify-content-center mt-5">
    <div class="col-xl-4 col-md-6 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">ログイン</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-4">メールアドレス</label>
              <div class="col-8">
                <input type="text" class="form-control" name="admin[email]" placeholder="メールを入力してください" v-model="adminData.email">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">パスワード</label>
              <div class="col-8">
                <input type="password" class="form-control" name="admin[password]" placeholder="パスワードを入力してください" v-model="adminData.password">
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <button class="btn btn-info pl-5 pr-5" type="submit">ログイン</button>
            <!-- <div class="mt-2">
              パスワードを忘れた方は<a :href="`${userRootUrl}/password/new`">こちら</a>
            </div> -->
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  data() {
    return {
      userRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      adminData: {
        email: null,
        password: null
      }
    };
  },

  methods: {
    onSubmit() {
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.userRootUrl}/sign_in`;
    }
  }
};
</script>

<style>

</style>
<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title header-title">メニュー編集</h3>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
          <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
            <input type="hidden" name="authenticity_token" :value="csrfToken">
            <input type="hidden" name="_method" value="put" />
            <div class="card-body row">
              <div  class="col-md-6">
                <div class="row">
                  <label class="col-xl-4 col-md-3">ユーザーID</label>
                  <div class="col-xl-8 col-md-9">
                    <!-- <a :href="`${adminRootUrl}/users/${user.id}`"> {{ user.id }} </a> -->
                    <input type="text" class="form-control" :value="user.id" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">ユーザー名</label>
                  <div class="col-xl-8 col-md-9">
                    <input type="text" class="form-control" :value="user.name" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">食事</label>
                  <div class="col-xl-8 col-md-9">
                    <input type="text" class="form-control" :value="mealTypeI18n" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">メニュー</label>
                  <div class="col-xl-8 col-md-9">
                    <v-select
                      :options="options"
                      :filterable="false"
                      @search="onSearch"
                      append-to-body
                      v-model="selected"
                      @input="onSelect"
                      label="name"
                    >
                    </v-select>
                    <input type="hidden" name="food[food_master_id]" v-model="foodForm.food_master_id">
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">数量</label>
                  <div class="col-xl-8 col-md-9">
                    <input type="text" class="form-control" name="food[volume]" v-model="foodForm.volume" @input="onInputVolume">
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">単位</label>
                  <div class="col-xl-8 col-md-9">
                    <input type="text" class="form-control" v-model="volumeUnit" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">カロリー</label>
                  <div class="col-xl-8 col-md-9">
                    <input type="text" class="form-control" name="food[calo]" v-model="foodForm.calo" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="col-xl-4 col-md-3">登録日時</label>
                  <div class="col-xl-8 col-md-9">
                    <input type="text" class="form-control" :value="moment(food.created_at)" disabled>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-center d-flex">
              <div class="btn btn-info mr-auto" @click="backToList()">食事詳細へ戻る</div>
              <submit-button class="mr-auto" object="メニュー" action="更新" :submitted="submitted"></submit-button>
            </div>
          </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';
import { mapActions } from 'vuex';

export default {
  props: ['food', 'meal', 'user', 'food_master'],
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      options: [],
      selected: null,
      submitted: false,
      mealTypeI18n: '',
      fmVolume: this.food_master.volume,
      fmUnit: this.food_master.unit,
      fmCalo: this.food_master.calo,
      volumeUnit: '',
      foodForm: {
        food_master_id: this.food.food_master_id,
        volume: this.food.volume,
        calo: this.food.calo
      },
      conditions: {
        name_cont: ''
      }
    };
  },

  created() {
    this.getAllFoodMasters();
    this.selected = this.food_master;
    this.volumeUnit = `${this.fmVolume}.${this.fmUnit}`;
    if (this.meal.type === 'dinner') {
      this.mealTypeI18n = '夕食';
    } else if (this.meal.type === 'snack') {
      this.mealTypeI18n = '間食';
    } else if (this.meal.type === 'lunch') {
      this.mealTypeI18n = '昼食';
    } else if (this.meal.type === 'breakfast') {
      this.mealTypeI18n = '朝食';
    }
  },

  methods: {
    ...mapActions('meal', ['searchFoodMasters']),

    async getAllFoodMasters(keyword) {
      var q = {};

      if (keyword) {
        this.conditions.name_cont = keyword;
      }

      for (const [key, value] of Object.entries(this.conditions)) {
        q[`q[${key}]`] = value;
      }
      const res = await this.searchFoodMasters(q);
      this.options = res.data.food_masters;
    },

    onSelect(value) {
      if (value) {
        this.volumeUnit = `${value.volume}.${value.unit}`;
        this.fmVolume = value.volume;
        this.fmCalo = value.calo;
        this.foodForm.calo = (this.foodForm.volume / this.fmVolume) * this.fmCalo;
        this.foodForm.food_master_id = value.id;
      }
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true);
      }
      const debouncedFunc = _.debounce(() => {
        this.getAllFoodMasters(search);
        loading(false);
      }, 500);
      debouncedFunc();
    },

    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },

    onInputVolume(e) {
      this.foodForm.calo = (e.target.value / this.fmVolume) * this.fmCalo;
    },

    getAction() {
      return `${this.adminRootUrl}/meals/${this.food.id}/update_food`;
    },

    backToList() {
      window.location = `${this.adminRootUrl}/meals/${this.meal.id}`;
    },

    moment(date) {
      return moment(date).tz('Asia/Tokyo').format('YYYY年MM月DD日');
    }
  }
};
</script>
<style lang="scss" scoped>

</style>

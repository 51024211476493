<template>
  <div class="position-relative">
    <input :type="inputType" :name="name" class="form-input" :class="inputType">
    <i class="mask fas fa-lg" :class="inputType == 'password' ? 'fa-eye' : 'fa-eye-slash'" @click="showPassword()"></i>
  </div>
</template>

<script>
export default {
  props: ['name'],
  data() {
    return {
      password: null,
      inputType: 'password'
    };
  },
  methods: {
    showPassword() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .form-input {
    display: block;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    height: 52px;
    border: none;
    width: 100%;
    font: small-caption;
    font-size: 40px;
    padding: 16px 12px;
    line-height: 20px;
  }

  .btn {
    margin-top: 24px !important;
    height: 48px !important;
    border-radius: 30px !important;
    width: 100% !important;
    &:disabled {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  .mask {
    position: absolute;
    right: 14px;
    top: calc(50% - 8px);
    color: #9E9694;
  }

  .text {
    font-size: 20px;
  }
  .password {
    font-size: 40px;
  }
</style>
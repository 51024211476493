<template>
  <v-select
    ref="itemListRef"
    :options="options"
    label="name"
    v-model="selected"
    @search="onSearch"
    @input="onSelect"
    placeholder="選択してください"
    append-to-body
  >
    <template #option="{ name, point }">
      {{ name }}
      <br />
      <cite>{{ point }}ポイント</cite>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ['items', 'selected'],

  data() {
    return {
      observer: null,
      options: this.items,
      keyword: ''
    };
  },

  watch: {
    items(newVal, oldVal) {
      this.options = _.cloneDeep(newVal);
    }
  },

  methods: {
    onSearch() {
      return _.debounce(() => {
        this.keyword = $('.vs__search').val();
        this.options = _.cloneDeep(this.options.filter(option => {
          return option.name.search(this.keyword) >= 0;
        }));
      }, 500);
    },

    onSelect(option) {
      this.$emit('update:selected', option);
    },

    clearInput() {
      this.$refs.itemListRef.clearSelection();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vs__selected-options {
    input {
      height: 30px !important;
    }
  }
}
</style>
import { API } from '../api';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {
  async validateUnique(context, query) {
    try {
      return await API.post('/walk_rallies/validate_unique', query);
    } catch (error) {
      return error.response.data;
    }
  }
};
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-body row justify-content-center"},[_c('div',{staticClass:"col-xl-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-md-3"},[_vm._v("年度"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-xl-9 col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedNendo.year),expression:"selectedNendo.year"}],staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.selectedNendo.year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedNendo, "year", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-md-3"},[_vm._v("有効期限"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-xl-9 col-md-9 d-flex"},[_c('div',{staticClass:"mw-200"},[_c('ValidationProvider',{attrs:{"name":"開始日時","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"datetime","hidden-name":"coupon[start_time]","phrases":{ok: '確定', cancel: '閉じる'},"placeholder":"日付を選択してください","zone":"Asia/Tokyo"},model:{value:(_vm.couponForm.start_time),callback:function ($$v) {_vm.$set(_vm.couponForm, "start_time", $$v)},expression:"couponForm.start_time"}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"mt-auto mb-auto mr-2 ml-2"},[_vm._v("-")]),_vm._v(" "),_c('div',{staticClass:"mw-200"},[_c('ValidationProvider',{attrs:{"name":"終了日時","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"datetime","hidden-name":"coupon[end_time]","phrases":{ok: '確定', cancel: '閉じる'},"placeholder":"日付を選択してください","min-datetime":_vm.couponForm.end_time,"zone":"Asia/Tokyo"},model:{value:(_vm.couponForm.end_time),callback:function ($$v) {_vm.$set(_vm.couponForm, "end_time", $$v)},expression:"couponForm.end_time"}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"offset-xl-3 offset-md-3 col-xl-9 col-md-9"},[(!_vm.datetimeValid)?_c('span',{staticClass:"error-explanation"},[_vm._v("別の時間範囲を選択してください。 選択した範囲がオーバーラップしました。")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-md-3"},[_vm._v("表示条件"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-xl-9 col-md-9 d-flex align-items-center"},[_c('ValidationProvider',{attrs:{"name":"表示条件ポイント数","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couponForm.required_point),expression:"couponForm.required_point"}],staticClass:"form-control",attrs:{"type":"number","name":"coupon[required_point]"},domProps:{"value":(_vm.couponForm.required_point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.couponForm, "required_point", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_vm._v("ポイント以上にのみ表示")])],1)]),_vm._v(" "),_c('image-picker',{attrs:{"imageUrl":_vm.couponForm.image_url,"imageType":_vm.isValidImageType,"name":'coupon[image]',"classLabel":'col-3',"classInput":'col-9 d-flex',"classErrors":'offset-3 col-9',"classImage":'coupon-image',"label":"画像","veeName":'画像',"veeRules":'required',"recommendation":"画像サイズは縦450px×横900px以上（縦横比1:2）を推奨"},on:{"file":function($event){_vm.couponForm.image = $event},"type":function($event){_vm.isValidImageType = $event}}})],1)]),_vm._v(" "),_c('div',{staticClass:"card-footer text-center d-flex"},[_c('div',{staticClass:"btn btn-info mr-auto",on:{"click":function($event){return _vm.backToList()}}},[_vm._v("一覧へ戻る")]),_vm._v(" "),_c('button',{staticClass:"mr-auto btn btn-info",attrs:{"disabled":invalid || _vm.isValidImageType},on:{"click":function($event){(invalid || _vm.isValidImageType) ? '' : _vm.onSubmit()}}},[(_vm.submitted)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("割引を作成")])],1)])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-name header-name"},[_vm._v("割引券編集")])])}]

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title" v-if="check_point">チェックポイント編集</h3>
          <h3 class="card-title header-title" v-else>チェックポイント新規作成</h3>
        </div>
        <ValidationObserver v-slot="{ invalid }">
        <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" v-if="check_point"/>
          <input type="hidden" name="check_point[id]" :value="checkpointForm.id">
          <div class="card-body row">
            <div  class="offset-xl-3 col-xl-6">
              <div class="form-group row">
                <label class="col-md-4">チェックポイント名<span class="label label-sm label-require">必須</span></label>
                <div class="col-md-8">
                <ValidationProvider name="チェックポイント名" rules="required|max:255" v-slot="{ errors }">
                  <input type="text" class="form-control" name="check_point[name]" placeholder="ウォークラリー名を入力してください" v-model="checkpointForm.name"/>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="row mt-2 justify-content-center">
                <label class="col-md-4">座標<span class="label label-sm label-require">必須</span></label>
                <div class="col-md-8">
                  <div class="flex-grow-1 d-flex align-self-end">
                    <div class="d-flex">
                      <ValidationProvider name="緯度" rules="required" v-slot="{ errors }">
                      <div class="flex-grow-1 mr-1">
                        <div>緯度 <span class="error-explanation">{{ errors[0] }}</span></div>
                        <input
                          type="text"
                          class="form-control"
                          name="check_point[lat]"
                          v-model="checkpointForm.lat"
                          @input="parseData"
                        >
                      </div>
                      </ValidationProvider>

                      <ValidationProvider name="軽度" rules="required" v-slot="{ errors }">
                      <div class="flex-grow-1 mr-1">
                        <div>軽度 <span class="error-explanation">{{ errors[0] }}</span></div>
                        <input
                          type="text"
                          class="form-control"
                          name="check_point[lng]"
                          v-model="checkpointForm.lng"
                          @input="parseData"
                        >
                      </div>
                      </ValidationProvider>
                      <div
                        class="btn btn-info mt-auto mb-0"
                        data-toggle="modal"
                        data-target="#locationPickerModal"
                      >
                        <i class="fas fa-globe-asia"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row mt-2">
                <label class="col-md-4">付与ポイント<span class="label label-sm label-require">必須</span></label>
                <div class="col-md-8">
                <ValidationProvider name="付与ポイント" rules="required|min:0" v-slot="{ errors }">
                  <input type="number" min="0" class="form-control" name="check_point[point]" v-model="checkpointForm.point">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="offset-xl-3 col-xl-6">
              <ValidationProvider name="アイコン" rules="required" v-slot="{ errors }">
                <span class="error-explanation">{{ errors[0] }}</span>
                <div>
                  <img :src="icon.image_url" v-if="icon" width="100" height="100" class=icon>
                  <div class="btn btn-info" data-toggle="modal" data-target="#iconPickerModal">アイコンの設定</div>
                  <input type="hidden" name="check_point[icon_id]" :value="icon.id" :v-model="icon.id" v-if="icon">
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="card-footer text-center d-flex">
            <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
            <submit-button class="mr-auto" object="チェックポイント" :action="check_point ? '更新' : '作成'" :submitted="submitted" :disabled="invalid"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
    <location-picker :lat.sync="checkpointForm.lat" :lng.sync="checkpointForm.lng" :onUpdate="this.parseData()"></location-picker>
    <icon-picker :icons="icons" :icon.sync="icon"></icon-picker>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['check_point', 'icons', 'icon'],

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      checkpointForm: {
        name: null,
        lat: null,
        lng: null,
        point: 1
      },
      submitted: false
    };
  },

  created() {
    if (this.check_point) {
      Object.assign(this.checkpointForm, this.check_point);
    }
  },

  methods: {
    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return this.check_point ? `${this.adminRootUrl}/check_points/${this.check_point.id}` : `${this.adminRootUrl}/check_points`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/check_points`;
    },

    parseData: _.debounce(function(e) {
      if (this.checkpointForm.lat) { this.checkpointForm.lat = Number.parseFloat(this.checkpointForm.lat).toFixed(6); }
      if (this.checkpointForm.lng) { this.checkpointForm.lng = Number.parseFloat(this.checkpointForm.lng).toFixed(6); }
    }, 1000)
  }
};
</script>

<style>
  .icon {
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }
</style>
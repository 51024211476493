<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">アイコンの作成</h3>
        </div>
        <ValidationObserver v-slot="{ invalid }">
        <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post" enctype="multipart/form-data">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" v-if="icon"/>
          <input type="hidden" name="check_point[id]" :value="iconForm.id">
          <div class="card-body offset-xl-3 col-xl-6">
            <div class="form-group row">
              <label class="col-4">アイコン名<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="アイコン名" rules="required|max:255" v-slot="{ errors }">
                <input type="text" class="form-control" name="icon[name]" v-model="iconForm.name" placeholder="アイコン名を入力してください">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>

            <!-- Start: upload image -->
            <image-picker
              :imageUrl = "iconForm.image_url"
              :imageType="isValidImageType"
              :name = "'icon[image]'"
              :classLabel = "'col-4'"
              :classInput = "'col-8 d-flex'"
              :classErrors = "'offset-4 col-8'"
              :classImage = "'icon-image'"
              :veeName = "'画像'"
              :veeRules = "'required'"
              @url="iconForm.image_url = $event"
              @type="isValidImageType = $event"
            ></image-picker>
            <!-- End: upload image -->
          </div>
          <div class="card-footer d-flex">
            <div class="btn btn-info" @click="backToList()">一覧へ戻る</div>
            <submit-button class="m-auto" object="アイコン" action="更新" :submitted="submitted" :disabled="invalid || isValidImageType"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['icon'],

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      iconForm: {
        id: null,
        name: null,
        image_url: null
      },
      submitted: false,
      isValidImageType: false
    };
  },

  created() {
    Object.assign(this.iconForm, this.icon);
  },

  methods: {
    async onSubmit(e) {
      if (this.isValidImageType) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return this.icon ? `${this.adminRootUrl}/icons/${this.icon.id}` : `${this.adminRootUrl}/icons`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/icons`;
    }
  }
};
</script>

<style>
  .avatar-preview {
    width: 100px;
    height: 100px;
    background-size: cover;
  }
</style>
<template>
  <div>
    <form ref="form" :action="getAction()" method="post">
      <input type="hidden" name="authenticity_token" :value="csrfToken">
      <div class="row">
        <div v-for="(option, index) in options" :key="index" class="checkbox icheck-info col-md-3 col-xl-2">
          <input type="checkbox" :value="option.value" :id="option.value" v-model="selectedOptions">
          <label :for="option.value">
            {{ option.name }}
          </label>
        </div>
        <input type="hidden" v-model="selectedOptions" name="q[csv_types][]">
      </div>
      <div class="d-flex mt-4">
        <daterange-picker
          class="ml-auto mw-300 mr-2"
          input_start_name="q[export_date_gteq]"
          input_end_name="q[export_date_lteq]"
          @submited="submittable=true"
          :max_date="maxDate"
        >
        </daterange-picker>
        <button type="button" class="btn btn-outline-info mr-auto" @click.prevent="onSubmit" :disabled="selectedOptions.length === 0 || !submittable">
          <i class="fas fa-download"></i> ZIP 作成処理をリクエスト
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { CsvTypes } from '@/utils/constant';
import Util from '@/utils/util.js';
import moment from 'moment-timezone';

export default {
  props: [''],
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      selectedOptions: [],
      options: CsvTypes,
      submittable: false,
      maxDate: moment().format()
    };
  },

  methods: {
    async onSubmit(e) {
      this.$refs.form.submit();
    },

    getAction() {
      return `${this.adminRootUrl}/dashboard/export`;
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
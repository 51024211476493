<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title header-title">イベント新規作成</h3>
      </div>
      <ValidationObserver v-slot="{ invalid }">
      <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post">
        <input type="hidden" name="authenticity_token" :value="csrfToken">
        <div class="card-body row justify-content-center">
          <div  class="col-xl-8">
            <!-- Title -->
            <div class="form-group row">
              <label class="col-4">タイトル<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="タイトル" rules="required|max:255" v-slot="{ errors }">
                <textarea class="form-control" rows="3" name="event[title]" placeholder="タイトルを入力してください" v-model="eventForm.title"></textarea>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- Description -->
            <div class="form-group row">
              <label class="col-4">説明</label>
              <div class="col-8">
                <textarea rows="8" class="form-control" name="event[description]" placeholder="説明を入力してください" v-model="eventForm.description"></textarea>
              </div>
            </div>
            <!-- URL -->
            <div class="form-group row">
              <label class="col-4">URL</label>
              <div class="col-8">
              <ValidationProvider name="URL" rules="url" v-slot="{ errors }">
                <input type="text" class="form-control" name="event[url]" placeholder="URLを入力してください" v-model="eventForm.url">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>

            <!-- ポイント獲得 -->
            <div class="form-group row">
              <label class="col-4">ポイント獲得</label>
              <div class="col-8">
              <ValidationProvider name="ポイント獲得" rules="min_value:0" v-slot="{ errors }">
                <input type="number" class="form-control" name="event[point]" placeholder="ポイント獲得を入力してください" v-model="eventForm.point">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>

            <!-- 上限回数 -->
            <div class="form-group row">
              <label class="col-4">上限回数</label>
              <div class="col-8">
              <ValidationProvider name="上限回数" rules="required|min_value:1" v-slot="{ errors }">
                <input type="number" class="form-control" name="event[capacity]" placeholder="上限回数を入力してください" v-model="eventForm.capacity">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>

            <!-- お知らせ日時  -->
            <div class="form-group row">
              <label class="col-4">イベント開始日時<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="イベント開始日時" rules="required" v-slot="{ errors }">
                <datetime
                  v-model="eventForm.start_time"
                  input-class="form-control"
                  type="datetime"
                  hidden-name="event[start_time]"
                  :phrases="{ok: '確定', cancel: '閉じる'}"
                  placeholder="日付を選択してください"
                  zone="Asia/Tokyo"
                ></datetime>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- お知らせ終了日時 -->
            <div class="form-group row">
              <label class="col-4">イベント終了日時<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="イベント終了日時" rules="required" v-slot="{ errors }">
                <datetime
                  v-model="eventForm.end_time"
                  input-class="form-control"
                  type="datetime"
                  hidden-name="event[end_time]"
                  :phrases="{ok: '確定', cancel: '閉じる'}"
                  placeholder="日付を選択してください"
                  :min-datetime="eventForm.end_time"
                  zone="Asia/Tokyo"
                ></datetime>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-center d-flex">
          <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
          <submit-button class="mr-auto" object="イベント" action="作成" :submitted="submitted" :disabled="invalid"></submit-button>
        </div>
      </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';
import { Datetime } from 'vue-datetime';

export default {
  components: {
    Datetime
  },
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      eventForm: {
        title: null,
        description: null,
        url: null,
        point: null,
        capacity: 1,
        start_time: moment().tz('Asia/Tokyo').format(),
        end_time: moment().add(5, 'days').tz('Asia/Tokyo').format()
      },
      submitted: false
    };
  },
  watch: {
    eventForm: {
      deep: true,
      handler() {
        if (this.eventForm.end_time < this.eventForm.start_time) {
          this.eventForm.end_time = this.eventForm.start_time;
        }
      }
    }
  },
  methods: {
    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/events`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/events`;
    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-name header-name">割引券編集</h3>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <div class="card-body row justify-content-center">
            <div  class="col-xl-8">
              <!-- nendo -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">年度<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9">
                  <input type="text" class="form-control" v-model="selectedNendo.year" disabled>
                </div>
              </div>

              <!-- 開始日時  -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">有効期限<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9 d-flex">
                  <div class="mw-200">
                    <ValidationProvider name="開始日時" rules="required" v-slot="{ errors }">
                      <datetime
                        v-model="couponForm.start_time"
                        input-class="form-control"
                        type="datetime"
                        hidden-name="coupon[start_time]"
                        :phrases="{ok: '確定', cancel: '閉じる'}"
                        placeholder="日付を選択してください"
                        zone="Asia/Tokyo"
                      ></datetime>
                      <span class="error-explanation">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="mt-auto mb-auto mr-2 ml-2">-</div>
                  <div class="mw-200">
                    <ValidationProvider name="終了日時" rules="required" v-slot="{ errors }">
                      <datetime
                        v-model="couponForm.end_time"
                        input-class="form-control"
                        type="datetime"
                        hidden-name="coupon[end_time]"
                        :phrases="{ok: '確定', cancel: '閉じる'}"
                        placeholder="日付を選択してください"
                        :min-datetime="couponForm.end_time"
                        zone="Asia/Tokyo"
                      ></datetime>
                      <span class="error-explanation">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="offset-xl-3 offset-md-3 col-xl-9 col-md-9">
                  <span class="error-explanation" v-if="!datetimeValid">別の時間範囲を選択してください。 選択した範囲がオーバーラップしました。</span>
                </div>
              </div>

              <!-- ポイント以上にのみ表示 -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">表示条件<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9 d-flex align-items-center">
                <ValidationProvider name="表示条件ポイント数" rules="required|min_value:1" v-slot="{ errors }">
                  <input type="number" class="form-control" name="coupon[required_point]" v-model="couponForm.required_point">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="ml-2">ポイント以上にのみ表示</div>
                </div>
              </div>

              <!-- 画像 -->
              <image-picker
                :imageUrl = "couponForm.image_url"
                :imageType="isValidImageType"
                :name = "'coupon[image]'"
                :classLabel = "'col-3'"
                :classInput = "'col-9 d-flex'"
                :classErrors = "'offset-3 col-9'"
                :classImage = "'coupon-image'"
                label="画像"
                :veeName = "'画像'"
                :veeRules = "'required'"
                recommendation = "画像サイズは縦450px×横900px以上（縦横比1:2）を推奨"
                @file="couponForm.image = $event"
                @type="isValidImageType = $event"
              ></image-picker>
            </div>
          </div>
          <div class="card-footer text-center d-flex">
            <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
            <button class="mr-auto btn btn-info" @click="(invalid || isValidImageType) ? '' : onSubmit()" :disabled="invalid || isValidImageType">
              <b-spinner small v-if="submitted"></b-spinner>
              <span >割引を作成</span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';
import { Datetime } from 'vue-datetime';
import { mapActions } from 'vuex';

export default {
  components: {
    Datetime
  },
  props: ['nendos', 'coupon'],
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      nendoOptions: null,
      couponForm: {
        id: null,
        nendo_id: null,
        start_time: moment().tz('Asia/Tokyo').format(),
        end_time: moment().add(5, 'days').tz('Asia/Tokyo').format(),
        image_url: null,
        image: null
      },
      submitted: false,
      isValidImageType: false,
      datetimeValid: true
    };
  },
  created() {
    if (this.coupon) {
      Object.assign(this.couponForm, this.coupon);
    }

    this.nendoOptions = _.cloneDeep(this.nendos);
    this.selectedNendo = this.nendoOptions.find((option) => option.id === this.couponForm.nendo_id);
    this.couponForm.nendo_id = this.selectedNendo.id;
  },
  watch: {
    couponForm: {
      deep: true,
      handler() {
        if (this.couponForm.end_time < this.couponForm.start_time) {
          this.couponForm.end_time = this.couponForm.start_time;
        }
      }
    }
  },
  methods: {
    ...mapActions('coupon', ['updateCoupon']),
    async onSubmit(e) {
      this.submitted = true;
      this.datetimeValid = true;
      const formData = new FormData();
      formData.append('nendo_id', this.couponForm.nendo_id);
      formData.append('start_time', this.couponForm.start_time);
      formData.append('end_time', this.couponForm.end_time);
      formData.append('required_point', this.couponForm.required_point);
      if (this.couponForm.image !== null) { formData.append('image', this.couponForm.image); };
      const data = [formData, this.couponForm.id];
      const res = await this.updateCoupon(data);
      if (res && !res.data) {
        this.datetimeValid = false;
      } else {
        window.toastr.success('割引券の変更は完了しました。');
        setTimeout(() => {
          window.location = `${this.adminRootUrl}/coupons/${res.data.id}`;
        }, 1000);
      }
      this.submitted = false;
    },
    getAction() {
      return `${this.adminRootUrl}/coupons/${this.coupon.id}`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/coupons`;
    },
    onSelectNendo(option) {
      if (option) {
        this.couponForm.nendo_id = option.id;
      }
    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">管理者編集</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form ref="form" @submit.prevent="handleSubmit(onUpdateAccount)" :action="getAction()" method="post" enctype="multipart/form-data">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" v-if="account"/>
          <input type="hidden" name="account[id]" :value="accountForm.id">
          <div class="card-body offset-xl-2 col-xl-8">
            <div class="form-group row">
              <label class="col-3">ID</label>
              <div class="col-9">
                <input type="text" class="form-control" name="account[id]" v-model="accountForm.id" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3">メールアドレス</label>
              <div class="col-9">
                <input type="text" class="form-control" name="account[email]" v-model="accountForm.email" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3">氏名<span class="label label-sm label-require">必須</span></label>
              <div class="col-9">
              <ValidationProvider name="氏名" :rules="!accountForm.password ? 'required|max:255' : ''" v-slot="{ errors }">
                <input type="text" class="form-control" name="account[name]" v-model="accountForm.name">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- Start: upload image -->
            <image-picker
              :imageUrl = "accountForm.avatar_url"
              :imageType="isValidImageType"
              :name = "'account[avatar]'"
              :classLabel = "'col-3'"
              :classInput = "'col-9 d-flex'"
              :classErrors = "'offset-3 col-9'"
              :classImage = "'user-image'"
              label="アバター"
              @url="accountForm.avatar_url = $event"
              @type="isValidImageType = $event"
            ></image-picker>
            <!-- End: upload image -->
            <div class="form-group row" v-if="current_admin.id !== account.id">
              <label class="col-3">無効 / 有効</label>
              <div class="col-9">
                <div class="btn-toggle">
                  <label class="switch" for="enabledCheck">
                    <input type="checkbox" id="enabledCheck" v-model="enabled" name="account[status]" @change="onActive">
                    <span class="slider round"></span>
                  </label>
                  <input type="hidden" name="account[status]" v-model="accountForm.status">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <button type="submit" class="btn btn-info mr-2" :disabled="isValidImageType">変更する</button>
          </div>
          </form>
        </ValidationObserver>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="card-title header-title">パスワード編集</div>
        </div>
        <div class="card-body">
          <ValidationObserver v-slot="{ invalid }">
            <div class="card-body offset-xl-2 col-xl-8">
              <div class="form-group row">
                <label class="col-3">新しいパスワード<span v-show="accountForm.password" class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="新しいパスワード" :rules="accountForm.password ? 'required|min:8|max:128' : ''" v-slot="{ errors }" vid="password">
                  <input type="text" class="form-control" name="account[password]" v-model="accountForm.password">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-3">新しいパスワード（確認用）<span v-show="accountForm.password" class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="新しいパスワード（確認用）" :rules="accountForm.password ? 'required|min:8|max:128|confirmed:password' : ''" v-slot="{ errors }">
                  <input type="text" class="form-control" name="account[password_confirmation]" v-model="accountForm.password_confirmation">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
              <button class="btn btn-info mr-2"  @click="invalid ? '' : onUpdatePassword()" :disabled="!accountForm.password || invalid">変更する</button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Util from '@/utils/util.js';

export default {
  props: ['account', 'avatar_url', 'current_admin'],
  data() {
    return {
      csrfToken: Util.getCsrfToken(),
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      accountForm: {
        id: null,
        name: null,
        email: null,
        status: null,
        avatar_url: null,
        password: null,
        password_confirmation: null
      },
      enabled: true,
      isValidImageType: false
    };
  },

  created() {
    Object.assign(this.accountForm, this.account);
    this.accountForm.avatar_url = this.avatar_url;
    this.accountForm.status === 'active' ? this.enabled = true : this.enabled = false;
  },

  methods: {
    ...mapActions('account', ['updateAccount']),

    async onUpdateAccount() {
      if (this.isValidImageType) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },

    async onUpdatePassword() {
      const formData = Object.assign({}, this.accountForm);

      delete formData.email;
      delete formData.name;
      delete formData.avatar_url;
      delete formData.status;

      const res = await this.updateAccount(formData);
      if (res.data) {
        window.toastr.success('新しいパスワードの設定は完了しました。');
      } else {
        window.toastr.errors('新しいパスワードの設定は失敗しました。');
      }
      this.backToList();
    },

    getAction() {
      return `${this.adminRootUrl}/accounts/${this.account.id}`;
    },

    backToList() {
      setTimeout(() => {
        window.location = `${this.adminRootUrl}/accounts`;
      }, 1000);
    },

    onActive() {
      this.enabled ? this.accountForm.status = 'active' : this.accountForm.status = 'blocked';
    }
  }
};
</script>
<style lang="scss" scoped>
 .user-image {
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 5px;
 }
</style>

<template>
  <div class="row p-0 m-0">
    <div class="col-md-12 offset-lg-4 col-lg-4 p-0">
      <div class="reset-password-box">
        <div class="app-name p-0 text-center">うつのみや健康ポイントアプリ</div>
        <div class="title-main text-center">パスワードの再設定</div>
        <div class="title-sub text-center">新しいパスワードを設定してください</div>
        <ValidationObserver v-slot="{ invalid }">
        <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="reset_password_token" :value="reset_password_token">
          <input type="hidden" name="_method" value="put"/>
          <div>
            <!-- パスワード -->
            <ValidationProvider name="パスワード" rules="required|min:8" vid="password" v-slot="{ errors }">
              <div class="form-group">
                <label>パスワード<span class="ml-2 pw-hint">英数字を含む8文字以上</span></label>
                <div class="pw">
                  <div class="position-relative">
                    <input :type="inputType" name="password" class="form-input" :class="inputType" v-model="password">
                    <i class="mask fas fa-lg" :class="inputType == 'password' ? 'fa-eye' : 'fa-eye-slash'" @click="showPassword()"></i>
                  </div>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>

            <!-- パスワード（確認用） -->
            <ValidationProvider name="パスワード(確認用）" rules="required|confirmed:password" v-slot="{ errors }" >
              <div class="form-group">
                <label>パスワード(確認用）</label>
                <div class="pw-confirm">
                  <div class="position-relative">
                    <input :type="inputType" name="password_confirmation" class="form-input" :class="inputType" v-model="password_confirmation">
                    <i class="mask fas fa-lg" :class="inputType == 'password' ? 'fa-eye' : 'fa-eye-slash'" @click="showPassword()"></i>
                  </div>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
          </div>
          <button class="btn" :disabled="invalid">更新する</button>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['reset_password_token'],

  data() {
    return {
      rootUrl: process.env.DOMAIN,
      csrfToken: Util.getCsrfToken(),
      password: null,
      password_confirmation: null,
      submitted: false,
      inputType: 'password'
    };
  },

  methods: {
    getAction() {
      return `${this.rootUrl}/api/v1/users/password`;
    },
    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    showPassword() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .error-explanation {
    font-size: 12px;
    margin-top: 2px;
  }

  .title-main {
    margin-top: 16px;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
  }

  .title-sub {
    margin-top: 24px;
    margin-bottom: 28px;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
  }

  label, .title-main, .title-sub {
    color: rgba(0, 0, 0, 0.87);
  }

  label {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }

  .form-input {
    display: block;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    height: 52px;
    border: none;
    width: 100%;
    font: small-caption;
    font-size: 40px;
    padding: 16px 12px;
    line-height: 20px;
  }

  .btn {
    margin-top: 24px !important;
    height: 48px !important;
    border-radius: 30px !important;
    width: 100% !important;
    &:disabled {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  .pw {
    margin-top: 8px;
  }

  .pw-confirm {
    margin-top: 8px;
  }

  .pw-hint {
    color: #6B6B6B;
  }

  .mask {
    position: absolute;
    right: 14px;
    top: calc(50% - 8px);
    color: #9E9694;
  }

  input.text {
    font-size: 20px;
  }
  input.password {
    font-size: 40px;
  }
</style>
<template>
  <div>
    <div class="form-group row">
      <label :class="classLabel">{{ label || '画像' }}<span class="label label-sm label-require" v-if="veeName && veeRules">必須</span></label>
      <div :class="classInput">
        <img v-if="imagePickerUrl" :src="imagePickerUrl" alt="Image" :class="classImage">
        <label class="btn btn-outline-info btn-block flex-grow-1" @click="openInput" v-if="!imagePickerUrl">
          <i class="far fa-image"></i>
            画像選択
        </label>
        <label class="btn btn-outline-secondary btn-sm ml-1 mt-auto" @click="openInput" v-else>
          <i class="fas fa-pencil-alt mr-1"></i>
          変更
        </label>
        <input ref="inputImage" id="data" type="file" accept="image/*" @change="onFileChanged($event.currentTarget.files[0])" :name="name" style="display:none;"/>
        <input type="hidden" :value="imagePickerUrl"/>
      </div>
      <div :class="classErrors">
        <div class="input-hint">ファイル形式：JPG、JPEG、PNG、GIFF、TIFF</div>
        <div class="input-hint" v-if="recommendation">{{ recommendation }}</div>
        <span class="error-explanation" v-if="isValidImageType && !veeName && !veeRules"> ファイルフォーマットが無効です。 </span>
        <ValidationProvider :name="veeName" :rules="veeRules" v-slot="{ errors }" v-if="veeName && veeRules">
          <input type="hidden" :value="imagePickerUrl"/>
          <span class="error-explanation" v-if="isValidImageType"> ファイルフォーマットが無効です。 </span>
          <span class="error-explanation" v-if="veeName && veeRules && errors && !isValidImageType">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'imageUrl', 'imageType', 'name', 'classLabel', 'classInput', 'classErrors', 'classImage', 'veeName', 'veeRules', 'recommendation'],
  data() {
    return {
      imagePickerUrl: null,
      isValidImageType: false
    };
  },
  created() {
    if (this.imageUrl) { this.imagePickerUrl = this.imageUrl; }
    if (this.imageType) { this.isValidImageType = this.imageType; }
  },
  methods: {
    openInput() {
      this.$refs.inputImage.click();
    },
    onFileChanged(input) {
      const mimeType = input.type;
      const mimesImage = mimeType.match('image.*');
      if (mimesImage) {
        const _this = this;
        const reader = new FileReader();
        reader.readAsArrayBuffer(input);
        reader.onload = function(e) {
          const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
          let header = '';
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          // image/png/0 - image/gif/1 - image/jpeg/23456 - image/tiff/78
          const mimeBytes = ['89504e47', '47494638', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffdb', 'ffd8ffee', '49492a00', '4d4d002a'];
          if (mimeBytes.includes(header.toString())) {
            reader.readAsDataURL(input);
            reader.onload = function(e) {
              _this.imagePickerUrl = e.target.result;
              _this.isValidImageType = false;
              _this.pushDataToParent(_this.imagePickerUrl, _this.isValidImageType, input);
            };
          } else {
            _this.removeImagePickerUrl();
          };
        };
      } else {
        this.removeImagePickerUrl();
      }
    },
    removeImagePickerUrl() {
      this.imagePickerUrl = '';
      this.isValidImageType = true;
      this.pushDataToParent(this.imagePickerUrl, this.isValidImageType);
    },
    pushDataToParent(url, type, file) {
      this.$emit('url', url);
      this.$emit('type', type);
      this.$emit('file', file);
    }
  }
};
</script>
<style lang="scss" scoped>
  .user-image {
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 5px;
  }

  .icon-image {
    object-fit: cover;
    width: 100px;
    height: 100px;
 }

 .reward-image, .coupon-image {
    object-fit: cover;
    width: 342px;
    height: 170px;
 }

 .banner-preview {
    object-fit: cover;
    width: 311px;
    height: 157px;
  }
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">お知らせ編集</h3>
        </div>
        <ValidationObserver v-slot="{ invalid }">
        <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post" enctype="multipart/form-data">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="announcement[id]" :value="announcementForm.id">
          <div class="card-body row justify-content-center">
            <div  class="col-md-8">
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">お知らせ種類<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9">
                  <ValidationProvider name="お知らせ種類" rules="required" v-slot="{ errors }" v-if="editable_all">
                    <v-select
                        :options="typeOptions"
                        label="title"
                        v-model="selectedType"
                        @input="onSeletecAnnouncementType"
                        placeholder="お知らせ種類を選択してください"
                        append-to-body
                    ></v-select>
                    <span class="error-explanation">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <input type="text" class="form-control" name="announcement[type]" v-model="selectedType.title"
                         disabled v-else>
                </div>
              </div>
              <!-- Title -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">タイトル<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9">
                <ValidationProvider name="タイトル" rules="required|max:255" v-slot="{ errors }">
                  <textarea class="form-control" name="announcement[title]" placeholder="タイトルを入力してください" v-model="announcementForm.title"></textarea>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- Body -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">本文<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9">
                <ValidationProvider name="本文" rules="required|max:1000" v-slot="{ errors }">
                  <textarea rows="8" class="form-control" name="announcement[body]" placeholder="本文を入力してください" v-model="announcementForm.body"></textarea>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- URL -->
              <div class="form-group row" v-show="selectedType.name === 'free_form' || selectedType.name === 'survey'">
                <label class="col-xl-3 col-md-3">URL</label>
                <div class="col-xl-9 col-md-9">
                <ValidationProvider name="URL" rules="url" v-slot="{ errors }">
                  <input type="text" class="form-control" name="announcement[url]" placeholder="URLを入力してください" v-model="announcementForm.url">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- 画像 -->
              <image-picker
                :imageUrl = "announcementForm.banner_url"
                :imageType="isValidImageType"
                :name = "'announcement[banner]'"
                :classLabel = "'col-xl-3 col-md-3'"
                :classInput = "'col-xl-9 d-flex'"
                :classErrors = "'offset-xl-3 offset-md-3 col-xl-9'"
                :classImage = "'banner-preview'"
                label="画像"
                @url="announcementForm.banner_url = $event"
                @type="isValidImageType = $event"
              ></image-picker>
              <!-- お知らせ日時  -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">お知らせ日時<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9">
                <ValidationProvider name="お知らせ日時" rules="required" v-slot="{ errors }">
                  <datetime
                    v-model="announcementForm.start_time"
                    input-class="form-control"
                    type="datetime"
                    hidden-name="announcement[start_time]"
                    :phrases="{ok: '確定', cancel: '閉じる'}"
                    placeholder="日付を選択してください"
                    value-zone="Asia/Tokyo"
                    zone="Asia/Tokyo"
                  ></datetime>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- お知らせ終了日時 -->
              <div class="form-group row" v-if="announcementForm.repeat !== 'none'">
                <label class="col-xl-3 col-md-3">お知らせ終了日時<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-9 col-md-9">
                <ValidationProvider name="お知らせ終了日時" rules="required" v-slot="{ errors }">
                  <datetime
                    v-model="announcementForm.end_time"
                    input-class="form-control"
                    type="datetime"
                    hidden-name="announcement[end_time]"
                    :phrases="{ok: '確定', cancel: '閉じる'}"
                    placeholder="日付を選択してください"
                    :min-datetime="announcementForm.start_time"
                    value-zone="Asia/Tokyo"
                    zone="Asia/Tokyo"
                  ></datetime>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- PUSH通知 -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">プッシュ通知する</label>
                <div class="col-xl-9 col-md-9">
                  <div class="icheck-info" v-if="editable_all">
                    <input type="checkbox" class="form-check-input" id="pushEnabledCheck" name="announcement[push_enabled]"
                           v-model="announcementForm.push_enabled">
                    <label for="pushEnabledCheck"></label>
                    <input type="hidden" class="form-check-input" id="pushEnabledCheck" name="announcement[push_enabled]" v-model="announcementForm.push_enabled">
                  </div>
                  <template v-else>
                    <div v-if="announcementForm.push_enabled" class="badge badge-success">有り</div>
                    <div v-else>なし</div>
                  </template>
                </div>
              </div>
              <!-- デバイス種類 -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">通知対象</label>
                <div class="col-xl-9 col-md-9 d-flex">
                  <template v-if="editable_all">
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="pushTargetOs_all" name="announcement[target_os]" v-model="announcementForm.target_os" value="all">
                      <label for="pushTargetOs_all">全て</label>
                    </div>
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="pushTargetOs_android" name="announcement[target_os]" v-model="announcementForm.target_os" value="android">
                      <label for="pushTargetOs_android">Androidのみ</label>
                    </div>
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="pushTargetOs_ios" name="announcement[target_os]" v-model="announcementForm.target_os" value="ios">
                      <label for="pushTargetOs_ios">iOSのみ</label>
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="announcementForm.target_os === 'all'">全て</div>
                    <div v-if="announcementForm.target_os === 'android'">Androidのみ</div>
                    <div v-if="announcementForm.target_os === 'ios'">iOSのみ</div>
                  </template>
                </div>
              </div>

              <!-- 繰り返し -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">繰り返し</label>
                <div class="col-xl-9 col-md-9 d-flex">
                  <template v-if="editable_all">
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="repeat_none" name="announcement[repeat]" v-model="announcementForm.repeat" value="none">
                      <label for="repeat_none">なし</label>
                    </div>
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="repeat_daily" name="announcement[repeat]" v-model="announcementForm.repeat" value="daily">
                      <label for="repeat_daily">毎日</label>
                    </div>
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="repeat_weekly" name="announcement[repeat]" v-model="announcementForm.repeat" value="weekly">
                      <label for="repeat_weekly">毎週</label>
                    </div>
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="repeat_monthly" name="announcement[repeat]" v-model="announcementForm.repeat" value="monthly">
                      <label for="repeat_monthly">毎月</label>
                    </div>
                    <div class="icheck-info flex-1 mr-5">
                      <input type="radio" class="form-check-input" id="repeat_yearly" name="announcement[repeat]" v-model="announcementForm.repeat" value="yearly">
                      <label for="repeat_yearly">毎年</label>
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="announcementForm.repeat === 'none'">なし</div>
                    <div v-if="announcementForm.repeat === 'daily'">毎日</div>
                    <div v-if="announcementForm.repeat === 'weekly'">毎週</div>
                    <div v-if="announcementForm.repeat === 'monthly'">毎月</div>
                    <div v-if="announcementForm.repeat === 'yearly'">毎年</div>
                  </template>
                </div>
              </div>
              <!-- Enabled / Disabled -->
              <div class="form-group row">
                <label class="col-xl-3 col-md-3">無効 / 有効</label>
                <div class="col-xl-9 col-md-9">
                    <label class="switch" for="enabledCheck">
                      <input type="checkbox" class="form-check-input" id="enabledCheck" name="announcement[enabled]" v-model="announcementForm.enabled">
                      <span class="slider round"></span>
                    </label>
                   <input type="hidden" class="form-check-input" id="enabledCheck" name="announcement[enabled]" v-model="announcementForm.enabled">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-center d-flex">
            <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
            <submit-button class="mr-auto" object="お知らせ" action="変更" :submitted="submitted" :disabled="invalid || isValidImageType"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';
import { Datetime } from 'vue-datetime';

export default {
  props: ['announcement', 'editable_all'],
  components: {
    Datetime
  },
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      selectedType: null,
      typeOptions: [
        { name: 'weight_registration', title: '体重登録の促進' },
        { name: 'medical_exam_registration', title: '健診受診の促進' },
        { name: 'walk_rally', title: 'ウォークラリー関係のお知らせ' },
        { name: 'point_exchange', title: 'ポイント交換関連のお知らせ' },
        // { name: 'meal_registration', title: '食事登録' },
        { name: 'qrcode', title: 'QRコード読み取り' },
        { name: 'survey', title: 'アンケート' },
        { name: 'free_form', title: 'その他' }
      ],
      announcementForm: {
        id: null,
        type: null,
        title: null,
        body: null,
        url: null,
        banner_url: null,
        start_time: moment().tz('Asia/Tokyo').format(),
        end_time: moment().tz('Asia/Tokyo').format(),
        target_os: null,
        push_enabled: true,
        repeat: 'none',
        enabled: true
      },
      submitted: false,
      isValidImageType: false
    };
  },
  created() {
    Object.assign(this.announcementForm, this.announcement);
    this.selectedType = this.typeOptions.find((option) => option.name === this.announcementForm.type);
  },
  methods: {
    async onSubmit(e) {
      if (this.isValidImageType) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/announcements/${this.announcement.id}`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/announcements`;
    },
    onSeletecAnnouncementType(option) {
      if (!option) return;

      this.announcementForm.type = option.name;
    }
  }
};
</script>

<style lang="scss" scoped>
  .switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.25rem;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0px;
      bottom: 0px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #17a2b8;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #17a2b8;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
</style>


import { API } from '../api';

export const state = {
};

export const mutations = {
};

export const getters = {

};

export const actions = {
  async searchActivity(context, conditions) {
    try {
      return await API.get('/map/trace_search', conditions);
    } catch (error) {
      return error;
    }
  },

  async searchUser(context, conditions) {
    try {
      return await API.get('/map/user_search', conditions);
    } catch (error) {
      return error;
    }
  },

  async exportActivity(context, data) {
    try {
      return await API.get('/map/export_activity', data);
    } catch (error) {
      return error;
    }
  },

  async exportLocationActivity(context, data) {
    try {
      return await API.get('/map/export_location_activity', data);
    } catch (error) {
      return error;
    }
  }
};
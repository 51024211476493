<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title" v-if="walk_rally">ウォークラリー編集</h3>
          <h3 class="card-title header-title" v-else>ウォークラリー新規作成</h3>
        </div>
        <ValidationObserver v-slot="{ invalid }">
        <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post" enctype="multipart/form-data">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put" v-if="walk_rally"/>
          <input type="hidden" name="walk_rally[id]" :value="walkrallyForm.id">
          <div class="card-body row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-xl-4 col-md-3">ウォークラリーID<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="ウォークラリーID" rules="required|max:255" v-slot="{ errors }">
                  <input type="text" class="form-control" name="walk_rally[uid]" placeholder="ウォークラリーIDを入力してください" v-model="walkrallyForm.uid" @change="getValidateUnique()">
                  <span class="error-explanation" v-if="!isUniqueUID"> ウォークラリーIDは既存しています。 </span>
                  <span class="error-explanation" v-else>{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-md-3">ウォークラリー名<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="ウォークラリー名" rules="required|max:255" v-slot="{ errors }">
                  <input type="text" class="form-control" name="walk_rally[name]" placeholder="ウォークラリー名を入力してください" v-model="walkrallyForm.name">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-md-3">ウォークラリー説明</label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="ウォークラリー説明" v-slot="{ errors }">
                  <textarea class="form-control" rows="3" name="walk_rally[description]" placeholder="ウォークラリー説明を入力してください" v-model="walkrallyForm.description"></textarea>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-md-3">開始日<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="開始日" rules="required" v-slot="{ errors }">
                  <datetime
                    v-model="walkrallyForm.start_at"
                    input-class="form-control"
                    type="datetime"
                    hidden-name="walk_rally[start_at]"
                    :phrases="{ok: '確定', cancel: '閉じる'}"
                    placeholder="日付を選択してください"
                    value-zone="Asia/Tokyo"
                    zone="Asia/Tokyo"
                  ></datetime>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-md-3">終了日<span class="label label-sm label-require">必須</span></label>
                <div class="col-xl-8 col-md-9">
                <ValidationProvider name="終了日" rules="required" v-slot="{ errors }">
                  <datetime
                    v-model="walkrallyForm.end_at"
                    input-class="form-control"
                    type="datetime"
                    hidden-name="walk_rally[end_at]"
                    :phrases="{ok: '確定', cancel: '閉じる'}"
                    placeholder="日付を選択してください"
                    :min-datetime="walkrallyForm.start_at"
                    value-zone="Asia/Tokyo"
                    zone="Asia/Tokyo"
                  ></datetime>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- ドキュメント -->
              <template v-if="walk_rally">
                <div class="form-group row">
                  <label class="col-xl-4 col-md-3">
                    ドキュメント<span class="label label-sm label-require">必須</span>
                  </label>
                  <div class="col-xl-8">
                    <div class="d-flex align-items-center mb-2">
                      <a :href="walkrallyForm.document_url">{{ walkrallyForm.document_name }}</a>
                    </div>
                      <ValidationProvider name="ドキュメント" rules="required|mimes:image/jpg,image/jpeg,image/png,image/gif,.pdf|size:10000" v-slot="{ errors, validate }">
                        <input ref="inputDocument" type="file" accept="image/*,.pdf" @change="validate" name="walk_rally[document]"/>
                        <div class="error-explanation">{{ errors[0] }}</div>
                      </ValidationProvider>
                    <div class="input-hint mt-2">ファイル形式：JPG、JPEG、PNG、GIF、PDF</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="form-group row">
                  <label class="col-xl-4 col-md-3">
                    ドキュメント<span class="label label-sm label-require">必須</span>
                  </label>
                  <div class="col-xl-8">
                    <ValidationProvider name="ドキュメント" rules="required|mimes:image/jpg,image/jpeg,image/png,image/gif,.pdf|size:10000" v-slot="{ errors, validate }">
                      <input ref="inputDocument" type="file" accept="image/*,.pdf" @change="validate" name="walk_rally[document]"/>
                      <div class="error-explanation">{{ errors[0] }}</div>
                    </ValidationProvider>
                    <div class="input-hint mt-2">ファイル形式：JPG、JPEG、PNG、GIF、PDF</div>
                  </div>
                </div>
              </template>
            </div>
            <div class="col-xl-6">
              <div class="d-flex">
                <check-point-select ref="checkPointSelectionRef" class="p-0 mr-0 flex-grow-1" :items="selectableCheckPoints" :selected.sync="selectedCheckPoint"></check-point-select>
                <div class="btn btn-info ml-2" @click="addCheckPoint()">追加</div>
              </div>
              <div class="mt-2">
                <table class="table table-bordered table-striped" v-if="walkrallyForm.check_point_ids.length > 0">
                  <thead>
                    <th>チェックポイント名</th>
                    <th>ポイント数</th>
                    <th></th>
                  </thead>
                  <tbody>
                    <template v-for="(checkPointId, index) in walkrallyForm.check_point_ids">
                      <tr :key="index">
                        <input type="hidden" name="walk_rally[check_point_ids][]" :value="checkPointId">
                        <td>{{ check_points.find(_ => _.id === checkPointId).name }}</td>
                        <td>{{ check_points.find(_ => _.id === checkPointId).point }}</td>
                        <td>
                          <div>
                            <a class="btn btn-outline-danger" @click="deleteCheckPoint(checkPointId)">
                              <i class="fas fa-trash"></i> 削除
                            </a>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <submit-button object="ウォークラリー" :action="walk_rally ? '更新' : '作成'" :submitted="submitted" :disabled="invalid || !isUniqueUID"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';
import { Datetime } from 'vue-datetime';
import { mapActions } from 'vuex';

export default {
  props: ['walk_rally', 'current_check_points', 'check_points'],

  components: {
    Datetime
  },

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      walkrallyForm: {
        id: null,
        uid: null,
        name: null,
        description: null,
        start_at: moment().tz('Asia/Tokyo').format(),
        end_at: moment().add(5, 'days').tz('Asia/Tokyo').format(),
        document_name: null,
        document_url: null,
        check_point_ids: []
      },
      selectableCheckPoints: [],
      selectedCheckPoint: null,
      submitted: false,
      isUniqueUID: true
    };
  },

  watch: {
    walkrallyForm: {
      deep: true,
      handler() {
        if (this.walkrallyForm.end_at < this.walkrallyForm.start_at) {
          this.walkrallyForm.end_at = this.walkrallyForm.start_at;
        }
      }
    }
  },

  created() {
    if (this.walk_rally) {
      Object.assign(this.walkrallyForm, this.walk_rally);
      this.walkrallyForm.check_point_ids = this.current_check_points.map(_ => _.id);
    }

    this.selectableCheckPoints = _.differenceBy(this.check_points, this.current_check_points, 'id');
  },

  methods: {
    ...mapActions('walkRally', ['validateUnique']),

    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    addCheckPoint() {
      this.walkrallyForm.check_point_ids = _.uniq(this.walkrallyForm.check_point_ids.concat(this.selectedCheckPoint.id));
      this.selectableCheckPoints = this.check_points.filter(_ => {
        return !this.walkrallyForm.check_point_ids.some(id => id === _.id);
      });
      this.$refs.checkPointSelectionRef.clearInput();
    },
    deleteCheckPoint(checkPointId) {
      this.walkrallyForm.check_point_ids = this.walkrallyForm.check_point_ids.filter(_ => _ !== checkPointId);
      this.selectableCheckPoints = this.check_points.filter(_ => {
        return !this.walkrallyForm.check_point_ids.some(id => id === _.id);
      });
    },
    getAction() {
      return this.walk_rally ? `${this.adminRootUrl}/walk_rallies/${this.walk_rally.id}` : `${this.adminRootUrl}/walk_rallies`;
    },
    openFilePicker() {
      this.$refs.inputDocument.click();
    },
    async getValidateUnique() {
      const data = { uid: this.walkrallyForm.uid, walk_rally_id: this.walkrallyForm.id };
      const res = await this.validateUnique(data);
      this.isUniqueUID = !!res && res.data.unique;
    }
  }
};
</script>

<style>
</style>
import { API } from '../api';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {

  async updateAccount(context, query) {
    try {
      return await API.put('/accounts/' + query.id, query);
    } catch (error) {
      return error;
    }
  },

  async validateUnique(context, query) {
    try {
      return await API.post('/accounts/validate_unique', query);
    } catch (error) {
      return error.response.data;
    }
  }

};

import { API } from '../api';

export const state = {
};

export const mutations = {
};

export const getters = {

};

export const actions = {
  async getUserStepsByCondition(context, conditions) {
    try {
      return await API.get('/dashboard/search', conditions);
    } catch (error) {
      return error;
    }
  }
};
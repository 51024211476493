<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-name header-name">物品新規作成</h3>
        </div>
        <div class="card-body">
          <ValidationObserver v-slot="{ invalid }">
          <form ref="form" @submit.prevent="onSubmit" :action="getAction()" method="post" enctype="multipart/form-data">
            <input type="hidden" name="authenticity_token" :value="csrfToken">
            <div class="offset-xl-2 col-xl-8">
              <!-- sort order -->
              <div class="form-group row">
                <label class="col-3">表示順<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="表示順" rules="required|min_value:0|max:4" v-slot="{ errors }">
                  <input type="number" class="form-control" name="reward[sort_order]" v-model="rewardForm.sort_order"/>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- nendo -->
              <div class="form-group row">
                <label class="col-3">年度<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                  <ValidationProvider name="年度" rules="required" v-slot="{ errors }">
                    <v-select
                      :options="nendoOptions"
                      v-model="selectedNendo"
                      label="year"
                      @input="onSelectNendo"
                      placeholder="年度を選択してください"
                      append-to-body
                    >
                    </v-select>
                    <input type="hidden" name="reward[nendo_id]" v-model="rewardForm.nendo_id">
                    <span class="error-explanation">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <!-- カテゴリー-->
              <div class="form-group row">
                <label class="col-3">カテゴリー<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                  <ValidationProvider name="カテゴリー" rules="required" v-slot="{ errors }">
                    <v-select
                      :options="categoryOptions"
                      label="title"
                      v-model="selectedCategory"
                      @input="onSelectCategory"
                      placeholder="カテゴリーを選択してください"
                      append-to-body
                    >
                    </v-select>
                    <input type="hidden" name="reward[type]" v-model="rewardForm.type">
                    <span class="error-explanation">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <!-- name -->
              <div class="form-group row">
                <label class="col-3">物品名称<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="物品名" rules="required|max:255" v-slot="{ errors }">
                  <textarea class="form-control" rows="3" name="reward[name]" placeholder="物品名を入力してください" v-model="rewardForm.name"></textarea>
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
              <!-- Description -->
              <div class="form-group row">
                <label class="col-3">説明</label>
                <div class="col-9">
                  <textarea rows="8" class="form-control" name="reward[description]" placeholder="説明を入力してください" v-model="rewardForm.description"></textarea>
                </div>
              </div>

              <!-- ポイント数 -->
              <div class="form-group row" v-if="rewardForm.type === 'commodity'">
                <label class="col-3">ポイント数<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="ポイント数" rules="required|min_value:1|max:9" v-slot="{ errors }">
                  <input type="number" class="form-control" name="reward[point]" placeholder="ポイント数を入力してください" v-model="rewardForm.point">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <!-- 当選人数 -->
              <div class="form-group row" v-if="rewardForm.type === 'lottery'">
                <label class="col-3">当選人数<span class="label label-sm label-require">必須</span></label>
                <div class="col-9">
                <ValidationProvider name="当選人数" rules="required|min_value:1|max:9" v-slot="{ errors }">
                  <input type="number" class="form-control" name="reward[capacity]" placeholder="当選人数を入力してください" v-model="rewardForm.capacity">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>

              <!-- 画像 -->
            <image-picker
              :imageUrl = "rewardForm.image_url"
              :imageType="isValidImageType"
              :name = "'reward[image]'"
              :classLabel = "'col-3'"
              :classInput = "'col-9 d-flex'"
              :classErrors = "'offset-3 col-9'"
              :classImage = "'reward-image'"
              :veeName = "'物品画像'"
              :veeRules = "'required'"
              recommendation = "画像サイズは縦450px×横900px以上（縦横比1:2）を推奨"
              @url="rewardForm.image_url = $event"
              @type="isValidImageType = $event"
            ></image-picker>
            </div>
            <div class="card-footer text-center d-flex">
              <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
              <submit-button class="mr-auto" object="物品" action="作成" :submitted="submitted" :disabled="invalid || isValidImageType"></submit-button>
            </div>
          </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import moment from 'moment-timezone';

export default {
  props: ['nendos', 'total_count'],
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      nendoOptions: null,
      selectedNendo: null,
      selectedCategory: null,
      categoryOptions: [
        { name: 'commodity', title: '交換物品' },
        { name: 'lottery', title: '抽選物品' },
        { name: 'gift', title: '寄付' }
      ],
      rewardForm: {
        id: null,
        type: null,
        nendo_id: null,
        name: null,
        description: null,
        point: 1,
        capacity: 1,
        image_url: null,
        sort_order: 0
      },
      submitted: false,
      isValidImageType: false
    };
  },
  created() {
    this.nendoOptions = _.cloneDeep(this.nendos);
    const currentYear = moment().year().toString();
    this.selectedNendo = this.nendoOptions.find((option) => option.year === currentYear);
    this.rewardForm.nendo_id = this.selectedNendo.id;
    this.selectedCategory = this.categoryOptions[0];
    this.rewardForm.type = this.selectedCategory.name;
  },
  methods: {
    async onSubmit(e) {
      if (this.isValidImageType) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/rewards`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/rewards`;
    },
    onSelectNendo(option) {
      if (option) {
        this.rewardForm.nendo_id = option.id;
      }
    },
    onSelectCategory(option) {
      if (option) {
        this.rewardForm.type = option.name;
      }
    }
  }
};
</script>
<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true" ref="vuemodal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content p-2">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">申請受付の削除</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table class="table table-bordered table-striped mt-3 text-left">
              <tbody>
                <tr>
                  <td class="w-200">物品ID</td>
                  <td class="text-prewrap"></td>
                </tr>
              </tbody>
            </table>
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">キャンセル</button>
          <a data-method="delete" :href="url" class="btn btn-danger">削除</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminUrl: process.env.VUE_APP_ADMIN_URL
    };
  },

  mounted() {
    $(this.$refs.vuemodal).on('show.bs.modal', this.shownModal);
  },

  computed: {
    url() {
      return `${this.adminurl}/exchange_requests/${this.id}`;
    }
  },

  methods: {
    shownModal() {
      if ($('#inputValue').val()) {
        this.id = $('#inputValue').val();
      }
    },

    onSubmit() {
      this.$emit('input');
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-modal-delete {
  background: #fb7c7c;
}

.btn-modal  {
  color: #fff;
  padding: 10px 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 200px;
  letter-spacing: .1em;
  display: block;
  width: 100%;
}

.modal-title {
  font-size: 16px;
}
</style>
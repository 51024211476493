var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"form",attrs:{"action":_vm.getAction(),"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('input',{attrs:{"type":"hidden","name":"authenticity_token"},domProps:{"value":_vm.csrfToken}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"_method","value":"put"}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"event[id]"},domProps:{"value":_vm.eventForm.id}}),_vm._v(" "),_c('div',{staticClass:"card-body row justify-content-center"},[_c('div',{staticClass:"col-xl-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("タイトル"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"タイトル","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.eventForm.title),expression:"eventForm.title"}],staticClass:"form-control",attrs:{"rows":"3","name":"event[title]","placeholder":"タイトルを入力してください"},domProps:{"value":(_vm.eventForm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.eventForm, "title", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("説明")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.eventForm.description),expression:"eventForm.description"}],staticClass:"form-control",attrs:{"rows":"8","name":"event[description]","placeholder":"説明を入力してください"},domProps:{"value":(_vm.eventForm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.eventForm, "description", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("URL")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.eventForm.url),expression:"eventForm.url"}],staticClass:"form-control",attrs:{"type":"text","name":"event[url]","placeholder":"URLを入力してください"},domProps:{"value":(_vm.eventForm.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.eventForm, "url", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("ポイント獲得")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"ポイント獲得","rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.eventForm.point),expression:"eventForm.point"}],staticClass:"form-control",attrs:{"type":"number","min":"0","name":"event[point]","placeholder":"ポイント獲得を入力してください"},domProps:{"value":(_vm.eventForm.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.eventForm, "point", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("上限回数")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"上限回数","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.eventForm.capacity),expression:"eventForm.capacity"}],staticClass:"form-control",attrs:{"type":"number","name":"event[capacity]","placeholder":"上限回数を入力してください"},domProps:{"value":(_vm.eventForm.capacity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.eventForm, "capacity", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("イベント開始日時"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"イベント開始日時","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"datetime","hidden-name":"event[start_time]","phrases":{ok: '確定', cancel: '閉じる'},"placeholder":"日付を選択してください","zone":"Asia/Tokyo"},model:{value:(_vm.eventForm.start_time),callback:function ($$v) {_vm.$set(_vm.eventForm, "start_time", $$v)},expression:"eventForm.start_time"}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("イベント終了日時"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"イベント終了日時","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"datetime","hidden-name":"event[end_time]","phrases":{ok: '確定', cancel: '閉じる'},"placeholder":"日付を選択してください","min-datetime":_vm.eventForm.end_time,"zone":"Asia/Tokyo"},model:{value:(_vm.eventForm.end_time),callback:function ($$v) {_vm.$set(_vm.eventForm, "end_time", $$v)},expression:"eventForm.end_time"}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_vm._v(" "),_c('div',{staticClass:"card-footer text-center d-flex"},[_c('div',{staticClass:"btn btn-info mr-auto",on:{"click":function($event){return _vm.backToList()}}},[_vm._v("一覧へ戻る")]),_vm._v(" "),_c('submit-button',{staticClass:"mr-auto",attrs:{"object":"イベント","action":"変更","submitted":_vm.submitted,"disabled":invalid}})],1)])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title header-title"},[_vm._v("イベント編集")])])}]

export { render, staticRenderFns }
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">ユーザー更新</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post" enctype="multipart/form-data">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <input type="hidden" name="_method" value="put"/>
          <div class="card-body offset-xl-2 col-xl-8">
            <div class="form-group row">
              <label class="col-4">ユーザーID</label>
              <div class="col-8">
                <input type="text" class="form-control" name="user[name]" v-model="userForm.id" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">ユーザー名</label>
              <div class="col-8">
              <ValidationProvider name="ユーザー名" rules="max:255" v-slot="{ errors }">
                <input type="text" class="form-control" name="user[name]" v-model="userForm.name">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">ニックネーム<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="ニックネーム" rules="required" v-slot="{ errors }">
                <input type="text" class="form-control" name="user[nick_name]" v-model="userForm.nick_name">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">ポイント数</label>
              <div class="col-8">
                <input type="text" class="form-control" name="user[point]" v-model="userForm.point" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">メール</label>
              <div class="col-8">
                <input type="text" class="form-control" name="user[email]" v-model="userForm.email" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">生年月日<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="生年月日" rules="required" v-slot="{ errors }">
                <input type="date" class="form-control" name="user[birthday]" v-model="userForm.birthday">
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">性別<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="性別" rules="oneOf:male,female,unknown" v-slot="{ errors }">
                <select class="form-control" name="user[gender]" v-model="userForm.gender">
                  <option value="male">男性</option>
                  <option value="female">女性</option>
                  <option value="unknown">無回答</option>
                </select>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">身長<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="身長" rules="required" v-slot="{ errors }">
                <div class="d-flex align-items-center">
                  <input type="number" step="0.01" class="form-control mr-2" name="user[height]" v-model="userForm.height"><span>cm</span>
                </div>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">体重<span class="label label-sm label-require">必須</span></label>
              <div class="col-8">
              <ValidationProvider name="体重" rules="required" v-slot="{ errors }">
                <div class="d-flex align-items-center">
                  <input type="number" step="0.01" class="form-control mr-2" name="user[weight]" v-model="userForm.weight"><span>kg</span>
                </div>
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
            <!-- Start: upload image -->
            <image-picker
              :imageUrl = "userForm.avatar_url"
              :imageType="isValidImageType"
              :name = "'user[avatar]'"
              :classLabel = "'col-4'"
              :classInput = "'col-8 d-flex'"
              :classErrors = "'offset-xl-4 offset-md-3'"
              :classImage = "'user-image'"
              label="アバター"
              @url="userForm.avatar_url = $event"
              @type="isValidImageType = $event"
            ></image-picker>
            <!-- End: upload image -->

            <div class="form-group row">
              <div class="offset-xl-4 col-xl-8">
                <div class="icheck-info">
                  <input type="checkbox" class="form-check-input" id="testerCheck" v-model="userForm.tester" name="user[tester]">
                  <label for="testerCheck">テストユーザー</label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <submit-button object="ユーザー" action="更新" :submitted="submitted"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  props: ['user', 'avatar_url'],

  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      userForm: {
        id: null,
        name: null,
        nick_name: null,
        point: null,
        mail: null,
        birthday: null,
        gender: null,
        height: null,
        weight: null,
        avatar_url: null,
        tester: false
      },
      submitted: false,
      isValidImageType: false
    };
  },

  created() {
    Object.assign(this.userForm, this.user);
    this.userForm.gender = this.userForm.gender || 'unknown';
    this.userForm.avatar_url = this.avatar_url;
  },

  methods: {
    async onSubmit(e) {
      if (this.isValidImageType) {
        return;
      }
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/users/${this.user.id}`;
    }
  }
};
</script>

<style>
  .avatar-preview {
    width: 100px;
    height: 100px;
    background-size: cover;
  }
</style>
<template>
  <div class="d-flex">
    <div class="w-300 box-search">
      <!-- Start: search condition -->
      <div><textarea class="w-100 status-search" rows="3" v-model="status" disabled></textarea></div>
      <div class="card card-outline card-info">
        <div class="card-header">
          <div class="card-title">検索条件</div>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <ValidationObserver v-slot="{ handleSubmit }">
          <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="get">
            <input type="hidden" name="authenticity_token" :value="csrfToken">
              <div>
                <label>中心座標(緯度, 経度)</label>
                <div class="input-group" data-target-input="nearest">
                  <input type="text" class="form-control" :value="`${center.lat.toFixed(6)} , ${center.lng.toFixed(6)}`">
                  <div class="input-group-append">
                    <div class="input-group-text"><i class="fas fa-globe-asia"></i></div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mt-2">
                <label class="mr-auto">拡大率</label><input type="number" class="form-control w-50" :value="zoom">
              </div>
              <div>
                <label>時間範囲</label>
                <div class="input-group d-flex" data-target-input="nearest">
                  <datetime
                    v-model="conditions.end_at_gteq"
                    class="flex-grow-1"
                    input-class="form-control pr-0"
                    type="datetime"
                    hidden-name="q[end_at_gteq]"
                    :phrases="{ok: '確定', cancel: '閉じる'}"
                    :max-datetime="conditions.end_at_lteq"
                    zone="Asia/Tokyo"
                  ></datetime>
                  <div class="input-group-append">
                    <div class="input-group-text"><i class="far fa-calendar-alt"></i></div>
                  </div>
                </div>
                <div class="input-group mt-1 d-flex">
                  <datetime
                    v-model="conditions.end_at_lteq"
                    class="flex-grow-1"
                    input-class="form-control pr-0"
                    type="datetime"
                    hidden-name="q[end_at_lteq]"
                    :phrases="{ok: '確定', cancel: '閉じる'}"
                    :min-datetime="conditions.end_at_gteq"
                    zone="Asia/Tokyo"
                  ></datetime>
                  <div class="input-group-append">
                    <div class="input-group-text"><i class="far fa-calendar-alt"></i></div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label>アクティビティ</label>
                <div class="d-flex row m-0">
                  <template v-for="(type, index) in activityTypes">
                    <div class="icheck-info flex-1 mr-2" :key="index">
                      <input type="checkbox" :id="`activityOptionCheck${index}`" name="q[type_in][]" v-model="conditions.type_in" :value="type.value"/>
                      <label :for="`activityOptionCheck${index}`">{{ type.name }}</label>
                    </div>
                  </template>
                </div>
              </div>
              <div class="mt-3">
                <label>性別</label>
                <div class="d-flex">
                  <template v-for="(gender, index) in genders">
                    <div class="icheck-info mr-2" :key="index">
                      <input type="checkbox" :id="`genderOptionCheck${index}`" name="q[user_gender_in][]" v-model="conditions.user_gender_in" :value="gender.value"/>
                      <label :for="`genderOptionCheck${index}`">{{ gender.name }}</label>
                    </div>
                  </template>
                </div>
              </div>
              <div class="mt-3">
                <label>年齢</label>
                <div class="d-flex">
                  <template v-for="(ageCondition, index) in ageConditions">
                    <div class="icheck-info flex-1 mr-2" :key="index">
                      <input type="radio" :id="`ageConditionOptionRadio${index}`" v-model="ageConditionSelected" :value="ageCondition.value"/>
                      <label :for="`ageConditionOptionRadio${index}`">{{ ageCondition.name }}</label>
                    </div>
                  </template>
                  <div class="icheck-info flex-1">
                    <input type="checkbox" id="ageSearchUnknownCheckbox" v-model="ageSearchUnknown" :value="ageSearchUnknown"/>
                    <label for="ageSearchUnknownCheckbox">(未登録を含む)</label>
                  </div>
                </div>
                <div v-if="ageConditionSelected === 'age_tier'" class="row m-0 d-flex">
                  <template v-for="(ageTierOption, index) in ageTierOptions">
                    <div class="icheck-info flex-1 mr-2" :key="index">
                      <input type="checkbox" :id="`ageTierOptionCheckbox${index}`" v-model="conditions.user_age_tier_in" :value="ageTierOption.value"/>
                      <label :for="`ageTierOptionCheckbox${index}`">{{ ageTierOption.name }}</label>
                    </div>
                  </template>
                </div>
                <div v-if="ageConditionSelected === 'age'" class="row my-4">
                  <template>
                    <div class="range-slider">
                      <div class="d-flex justify-content-center"> {{ conditions.user_age_gteq }} - {{ conditions.user_age_lteq }}</div>
                      <input type="range" min="0" max="100" step="1" name="q[user_age_gteq]" v-model="sliderAgeMin">
                      <input type="range" min="0" max="100" step="1" name="q[user_age_lteq]" v-model="sliderAgeMax">
                    </div>
                  </template>
                </div>
              </div>
              <submit-button action="検索" classes="btn-block" :submitted="submitted"></submit-button>
          </form>
          </ValidationObserver>
        </div>
      </div>
      <!-- 表示設定 -->
      <div class="card card-outline card-info">
        <div class="card-header">
          <div class="card-title">表示設定</div>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <div class="icheck-info">
            <input type="checkbox" id="drawOptionCheckGeo" v-model="drawOptions.geo"/>
            <label for="drawOptionCheckGeo">GPSデータを表示する</label>
          </div>
          <div class="icheck-info">
            <input type="checkbox" id="drawOptionCheckTurningPoints" v-model="drawOptions.turning_points" @change="changeVisible('turning_points', drawOptions.turning_points)" />
            <label for="drawOptionCheckTurningPoints">分岐点を表示する</label>
          </div>
          <div class="icheck-info">
            <input type="checkbox" id="drawOptionCheckClusters" v-model="drawOptions.clusters" @change="changeVisible('clusters', drawOptions.clusters)" />
            <label for="drawOptionCheckClusters">データをクラスタリングする</label>
          </div>
          <div class="icheck-info">
            <input type="checkbox" id="drawOptionCheckPaths" v-model="drawOptions.paths" @change="changeVisible('paths', drawOptions.paths)" />
            <label for="drawOptionCheckPaths">パスを表示する</label>
          </div>
        </div>
      </div>
      <!-- ユーザ指定 -->
      <div class="card card-outline card-info">
        <div class="card-header">
          <div class="card-title">ユーザ指定</div>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <div>
            <label>選択ユーザID [{{ selectedUserIds.length }}名選択]</label>
            <textarea class="form-control w-100" :value="listSelectedUser()" rows="5" data-toggle="tooltip" disabled></textarea>
          </div>
          <div>
            <label>ユーザリスト [計{{ users.length }}名] </label>
            <div class="box-search-users" :class="users.length > 0 ? 'active-box-search-users' : ''">
              <table class="w-100">
                <tbody>
                  <tr v-for="(user, index) in users" :key="index">
                    <td
                      class="search-user w-90"
                      :class="selectedUserIndexes.includes(index) ? 'active-search-user' : ''"
                      @click="!selectedUserIndexes.includes(index) ? selectionUser(user, index) : ''"
                    >
                    {{ user.id }}
                    </td>
                    <td><a class="remove-user" v-if="selectedUserIndexes.includes(index)" @click="deselectUser(user, index)"><i class="fas fa-times"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-2">
            <a class="btn btn-sm btn-info" @click="selectionAllUser()">全て選択</a>
            <a class="btn btn-sm btn-info" @click="unSelectAllUser()">選択解除</a>
          </div>
          <div class="icheck-info">
            <input type="checkbox" id="conditionallyDraw" v-model="conditionallyDraw" @change="changeDrawCondition()"/>
            <label for="conditionallyDraw">選択したユーザーを保持して描画、出力</label>
          </div>

          <button
            class="btn btn-info btn-block mt-2"
            :class="users.length != 0 && selectedUserIds.length > 0 ? '' : 'disabled'"
            @click="users.length != 0 && selectedUserIds.length > 0 ? onDrawAction() : ''"
          >
            <span v-if="onDraw">
              <b-spinner small></b-spinner>
            </span>
            行動経路を描画
          </button>
        </div>
      </div>
      <!-- export csv -->
      <div class="card card-outline card-info">
        <div class="card-header">
          <div class="card-title">CSVファイルの出力</div>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <div
            class="btn btn-info btn-block mt-2"
            :class="users.length > 0 ? '' : 'disabled'"
            @click="users.length > 0 ? exportActivityCsv() : ''"
          >
            <span v-if="statusExport === 'export_activity_csv'">
              <b-spinner small></b-spinner>
            </span>
            アクティビティデータ
          </div>
          <div
            class="btn btn-info btn-block mt-2"
            :class="users.length > 0 ? '' : 'disabled'"
            @click="users.length > 0 ? exportLocationCsv() : ''"
          >
            <span v-if="statusExport === 'export_location_csv'">
              <b-spinner small></b-spinner>
            </span>
            位置情報データ
          </div>
        </div>
      </div>
    </div>
    <div class="pl-2 flex-grow-1">
      <div class="map-canvas">
        <l-map
          ref="map"
          :zoom="zoom"
          min-zoom="4"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
          @ready="onMapReady()"
        >
          <l-tile-layer :url="url" no-wrap="true"></l-tile-layer>
          <a id="selected_range_download"></a>
          <input id="selected_range_upload" type="file" accept=".geojson" style="display: none" hidden="">
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/utils/util.js';
import { LMap, LTileLayer } from 'vue2-leaflet';
import moment from 'moment-timezone';
import L from 'leaflet';
import * as d3 from 'd3';
import { antPath } from 'leaflet-ant-path';
import MapHelper from '@/utils/map-helper';
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { AgeConditions, AgeTierOptions, activityTypes, genders } from '@/utils/constant';
/* eslint-disable no-unused-vars */
import 'leaflet-draw';
/* eslint-enable no-unused-vars */
// Import leaflet draw css and icons for draw toolbar
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-easybutton';
import 'leaflet-easybutton/src/easy-button.css';

export default {
  components: {
    Datetime,
    LMap,
    LTileLayer
  },
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      usersData: null,
      map: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: L.latLng(35.69, 139.70),
      bounds: null,
      drawingCount: 0,
      userColorIndex: 0,
      accuracy: 6,
      ageConditions: AgeConditions,
      ageConditionSelected: 'age_tier',
      ageTierOptions: AgeTierOptions,
      ageSearchUnknown: false,
      drawOptions: {
        geo: true,
        turning_points: false,
        clusters: false,
        paths: false
      },
      drawItems: {
        circles: [],
        clusters: [],
        paths: [],
        turning_points: [],
        flows: []
      },
      activityTypes: activityTypes,
      genders: genders,
      conditions: {
        end_at_gteq: moment().tz('Asia/Tokyo').format('yyyy-MM-DD'),
        end_at_lteq: moment().tz('Asia/Tokyo').format(),
        type_in: ['walk', 'transport', 'bicycling', 'stay'],
        zoom: null,
        center_lat: null,
        center_lng: null,
        user_gender_in: ['male', 'female', 'unknown'],
        user_age_tier_in: [],
        user_age_null: false,
        user_age_gteq: 0,
        user_age_lteq: 100,
        user_id_in: [],
        search_type: 'normal',
        search_boundary: null,
        search_radius: null
      },
      submitted: false,
      users: [],
      selectedUserIds: [],
      selectedUserIndexes: [],
      onDraw: false,
      status: '検索開始 \nデータはありません。',
      conditionallyDraw: true,
      statusExport: null
    };
  },

  created() {
    this.conditions.user_age_tier_in = this.ageTierOptions.map((_) => _.value);
  },

  computed: {
    sliderAgeMin: {
      get() {
        const valueMin = parseInt(this.conditions.user_age_gteq);
        return valueMin;
      },
      set(valueMin) {
        valueMin = parseInt(valueMin);
        if (valueMin > this.conditions.user_age_lteq) {
          this.conditions.user_age_lteq = valueMin;
        }
        this.conditions.user_age_gteq = valueMin;
      }
    },

    sliderAgeMax: {
      get() {
        const valueMax = parseInt(this.conditions.user_age_lteq);
        return valueMax;
      },
      set(valueMax) {
        valueMax = parseInt(valueMax);
        if (valueMax < this.conditions.user_age_gteq) {
          this.conditions.user_age_gteq = valueMax;
        }
        this.conditions.user_age_lteq = valueMax;
      }
    }
  },

  methods: {
    ...mapActions('map', ['searchActivity', 'searchUser', 'exportLocationActivity', 'exportActivity']),
    async onSubmit(e) {
      this.clearAllLayers();
      this.unSelectAllUser();
      this.clearMapUser();
      this.submitted = true;
      var q = {};
      const formData = Object.assign({}, this.conditions);
      let age = false;
      let ageTier = false;

      if (this.ageConditionSelected === 'age') {
        delete formData.user_age_tier_in;
        age = true;
      } else if (this.ageConditionSelected === 'age_tier') {
        delete formData.user_age_gteq;
        delete formData.user_age_lteq;
        ageTier = true;
      }

      if (this.ageSearchUnknown) {
        formData.user_age_null = true;
      }
      for (const [key, value] of Object.entries(formData)) {
        q[`q[${key}]`] = value;
      }
      const res = await this.searchUser(q);
      this.users = res.data;
      this.status = `検索開始 \n${this.users.length}ユーザーが見つかりました。`;
      this.users.forEach((user) => {
        window.sessionStorage.setItem(`map/user/${user.id}`, user.id);
      });

      let male = false;
      let female = false;
      let unknown = false;

      if (this.conditions.user_gender_in.length > 0) {
        this.conditions.user_gender_in.forEach((gender) => {
          if (gender.toString() === 'male') {
            male = true;
          } else if (gender.toString() === 'female') {
            female = true;
          } else if (gender.toString() === 'unknown') {
            unknown = true;
          }
        });
      }
      window.sessionStorage.setItem('selectedSettings',
        `zoom=${this.zoom}&c_lat=${this.center.lat}&c_lng=${this.center.lng}&end_at_lteq=${moment(this.conditions.end_at_lteq).format()}&end_at_gteq=${moment(this.conditions.end_at_gteq).format()}&male=${male}&female=${female}&unknown=${unknown}&age_tier=${ageTier}&user_age_gteq=${this.conditions.user_age_gteq}&user_age_lteq=${this.conditions.user_age_lteq}&age=${age}&user_age_tier_in=${this.conditions.user_age_tier_in}`
      );
      this.submitted = false;
    },

    async onDrawAction() {
      this.onDraw = true;
      this.status = '検索開始 \n活動を描き始めます。';
      window.sessionStorage.removeItem('storageSelectedUserIds');
      var q = {};
      const formData = Object.assign({}, this.conditions);
      if (this.ageConditionSelected === 'age') {
        delete formData.user_age_tier_in;
      } else if (this.ageConditionSelected === 'age_tier') {
        delete formData.user_age_gteq;
        delete formData.user_age_lteq;
      }
      if (this.ageSearchUnknown) {
        formData.user_age_null = true;
      }

      // if (!this.conditionallyDraw) {
      //   this.selectionAllUser();
      // }

      if (this.conditions.user_id_in.length > 0) {
        for (const [key, value] of Object.entries(formData)) {
          q[`q[${key}]`] = value;
        }
        const res = await this.searchActivity(q);
        const activities = res.data;
        this.consumeAndDraw(activities);
      }
      const arrTotalTrackPoint = [];
      for (let i = 0; i < this.usersData.length; i++) {
        let TotalTrackPoint = 0;
        for (let j = 0; j < this.usersData[i].activities.length; j++) {
          TotalTrackPoint = this.usersData[i].activities[j].track_points.length + TotalTrackPoint;
        }
        arrTotalTrackPoint.push(`\n${TotalTrackPoint}アイテムが表示された`);
      }
      const statusTrackPoint = arrTotalTrackPoint.toString();
      window.sessionStorage.setItem('storageSelectedUserIds', this.selectedUserIds);
      this.status = `${this.selectedUserIds.length}ユーザーが見つかりました。\n活動を描き始めます。${statusTrackPoint}\n描画完了！`;
      this.onDraw = false;
    },

    getAction() {
      return `${this.adminRootUrl}/map/trace`;
    },

    onMapReady() {
      this.initMap();
    },
    initMap() {
      this.map = this.$refs.map.mapObject;
      this.loadSearchConfigs();
      this.map.createPane('circles').style.zIndex = 450;
      this.conditions.zoom = this.zoom;
      this.conditions.center_lat = this.center.lat;
      this.conditions.center_lng = this.center.lng;
      this.selectionInit(this.map, ['polygon', 'rectangle', 'circle']);
    },

    loadSearchConfigs() {
      const keys = Object.keys(sessionStorage);
      let totalUsers = 0;
      keys.forEach((key) => {
        if (key.slice(0, 9) === 'map/user/') {
          const userId = window.sessionStorage.getItem(key.toString());
          const userCode = key.slice(9, key.length);
          this.users.push({ code: userCode, id: userId });
          totalUsers += 1;
        }
      });
      if (totalUsers > 0) {
        this.status = `${totalUsers}ユーザーが読み込まれました。`;
      }
      // init search
      const selectedSettings = window.sessionStorage.getItem('selectedSettings');
      if (selectedSettings) {
        selectedSettings.split('&').forEach((e) => {
          const arrSplit = e.split('=');
          if (arrSplit[0] === 'zoom') {
            this.zoom = Number(arrSplit[1]);
          } else if (arrSplit[0] === 'c_lat') {
            this.center.lat = parseFloat(arrSplit[1]);
          } else if (arrSplit[0] === 'c_lng') {
            this.center.lng = parseFloat(arrSplit[1]);
          } else if (arrSplit[0] === 'end_at_gteq') {
            const endAtGteq = arrSplit[1];
            this.conditions.end_at_gteq = moment(new Date(endAtGteq)).format();
          } else if (arrSplit[0] === 'end_at_lteq') {
            const endAtLteq = arrSplit[1];
            this.conditions.end_at_lteq = moment(new Date(endAtLteq)).format();
          } else if (arrSplit[0] === 'male') {
            if (arrSplit[1].toString() === 'false') {
              _.remove(this.conditions.user_gender_in, function(e) {
                return e.toString() === 'male';
              });
            };
          } else if (arrSplit[0] === 'female') {
            if (arrSplit[1].toString() === 'false') {
              _.remove(this.conditions.user_gender_in, function(e) {
                return e.toString() === 'female';
              });
            };
          } else if (arrSplit[0] === 'unknown') {
            if (arrSplit[1].toString() === 'false') {
              _.remove(this.conditions.user_gender_in, function(e) {
                return e.toString() === 'unknown';
              });
            };
          } else if (arrSplit[0] === 'age_tier') {
            if (arrSplit[1].toString() === 'true') { this.ageConditionSelected = 'age_tier'; }
          } else if (arrSplit[0] === 'user_age_gteq') {
            this.conditions.user_age_gteq = Number(arrSplit[1]);
          } else if (arrSplit[0] === 'user_age_lteq') {
            this.conditions.user_age_lteq = Number(arrSplit[1]);
          } else if (arrSplit[0] === 'age') {
            if (arrSplit[1].toString() === 'true') { this.ageConditionSelected = 'age'; }
          } else if (arrSplit[0] === 'user_age_tier_in') {
            this.conditions.user_age_tier_in = arrSplit[1].split(',');
          }
        });
      }
      // init draw
      const storageSelectedUserIds = window.sessionStorage.getItem('storageSelectedUserIds');
      if (storageSelectedUserIds) {
        this.users.forEach((user, index) => {
          if (storageSelectedUserIds.split(',').includes(user.id)) {
            this.selectionUser(user, index);
          }
        });
      }
    },

    clearMapUser() {
      const keys = Object.keys(sessionStorage);
      keys.forEach((key) => {
        if (key.slice(0, 9) === 'map/user/') {
          window.sessionStorage.removeItem(key.toString());
        }
      });
      window.sessionStorage.removeItem('selectedSettings');
      window.sessionStorage.removeItem('storageSelectedUserIds');
    },

    // map             : 選択範囲描画機能を追加する L.map インスタンス
    // selection_types : 有効にする描画タイプ配列
    // f               : 描画内容が変化したときによばれるコールバック json （詳細は以下）
    // multiple        : 複数のオブジェクトを描画可能にするかどうか
    //
    // コールバック
    // created: 描画追加時によばれ、引数に対象のレイヤーがわたされます
    // edited : 描画内容が編集時によばれ、引数に描画されている全ての FeatureGroup ががわたされます
    // deteled: 描画削除時によばれ、引数に描画されている全ての FeatureGroup がわたされます
    // change : 上記全てのケースのあとに呼ばれて描画されている全ての FeatureGroup が引数として渡されます
    selectionInit(map, selectionTypes, multiple = false, file = true) {
      const _this = this;
      this.$nextTick(() => {
        // 選択中の領域情報を保持
        map.selectionType = null;
        map.selectionLayer = null;

        // 描画中のマウスの状態を保持
        let drawStart = false;
        let editStart = false;
        let lastMouseLatLng = null;
        let searchBoundary = null;

        const drawnItems = new L.FeatureGroup();

        // 選択ツールチップの日本語化
        // draw toolbar
        this.drawTranslations();
        var AUTOSCROLL_DISTANCE_TO_BORDER_LIMIT = 0.0001;
        var AUTOSCROLL_OFFSET = 0.00025;

        // 選択領域を表示するレイヤーを追加
        map.addLayer(drawnItems);

        const SELECTION_RANGE_COLOR = 'magenta';
        const SELECTION_RANGE_WEIGHT = 2;
        // drawControl を生成
        const defaultOption = {
          shapeOptions: {
            color: SELECTION_RANGE_COLOR,
            weight: SELECTION_RANGE_WEIGHT
          }
        };
          // 連続選択可能に
        if (multiple) { defaultOption.repeatMode = true; }

        const drawOptions = {
          polyline: false,
          marker: false,
          circlemarker: false,
          polygon: false,
          rectangle: false,
          circle: false
        };
          // selection_types に指定されたものだけ有効に
        for (const t of selectionTypes) { drawOptions[t] = defaultOption; }

        var drawControl = new L.Control.Draw({
          draw: drawOptions,
          edit: { featureGroup: drawnItems }
        });

        map.control = drawControl;
        map.addControl(drawControl);

        map.on('draw:created', function(e) {
          var type = e.layerType;
          var layer = e.layer;

          map.selectionType = type;
          map.selectionLayer = layer;
          drawnItems.addLayer(layer);

          if (type === 'marker') {
          // Do marker specific actions
          }
          if (layer.bringToBack) { layer.bringToBack(); }

          drawStart = false;

          // 複数追加するときは連続で同じ type を選択
          if (multiple) {
            this.fireDrawStart(map, type);
          }

          if (type === 'circle') {
            const lat = JSON.stringify(layer._latlng.lat);
            const lon = JSON.stringify(layer._latlng.lng);
            const radius = JSON.stringify(layer._mRadius);
            searchBoundary = [];
            searchBoundary.push({ lat: lat, lon: lon });
            _this.conditions.search_radius = radius;
            _this.conditions.search_type = 'circle';
          }
          if (type === 'rectangle') {
            const data = layer._latlngs[0];
            searchBoundary = [];
            for (let i = 0; i < data.length; i++) {
              const lat = JSON.stringify(data[i].lat);
              const lon = JSON.stringify(data[i].lng);
              searchBoundary.push({ lat: lat, lon: lon });
            }
            _this.conditions.search_type = 'polygon';
          }
          if (type === 'polygon') {
            const data = layer._latlngs[0];
            searchBoundary = [];
            for (let i = 0; i < data.length; i++) {
              const lat = JSON.stringify(data[i].lat);
              const lon = JSON.stringify(data[i].lng);
              searchBoundary.push({ lat: lat, lon: lon });
            }
            _this.conditions.search_type = 'polygon';
          }

          _this.conditions.search_boundary = searchBoundary || [];
        });

        map.on('draw:edited', function(e) {
          var layers = e.layers;
          layers.eachLayer(function(layer) {
            map.selectionLayer = layer;

            if (_this.conditions.search_type === 'circle') {
              const lat = JSON.stringify(layer._latlng.lat);
              const lng = JSON.stringify(layer._latlng.lng);
              const radius = JSON.stringify(layer._mRadius);
              searchBoundary = [];
              searchBoundary.push({ lat: lat, lon: lng });
              _this.conditions.search_radius = radius;
            }
            if (_this.conditions.search_type === 'rectangle') {
              const data = layer._latlngs[0];
              searchBoundary = [];
              for (let i = 0; i < data.length; i++) {
                const lat = JSON.stringify(data[i].lat);
                const lng = JSON.stringify(data[i].lng);
                searchBoundary.push({ lat: lat, lon: lng });
              }
            }
            if (_this.conditions.search_type === 'polygon') {
              const data = layer._latlngs[0];
              searchBoundary = [];
              for (let i = 0; i < data.length; i++) {
                const lat = JSON.stringify(data[i].lat);
                const lng = JSON.stringify(data[i].lng);
                searchBoundary.push({ lat: lat, lon: lng });
              }
            }
            _this.conditions.search_boundary = searchBoundary || null;
          });
          editStart = false;
        });

        map.on('draw:deleted', function(e) {
          map.selectionType = null;
          map.selectionLayer = null;
          _this.conditions.search_boundary = null;
          _this.conditions.search_type = 'normal';
        });

        map.on('draw:drawstart', function(e) {
          if (!multiple && map.selectionLayer !== null) {
            alert('選択範囲は一つしか出来ませんので、既存の選択範囲を削除してから新しい範囲を選択してください。');
            drawControl._toolbars.draw.disable();
            return;
          }

          drawStart = true;
        });

        map.on('draw:editstart', function(e) {
          editStart = true;
        });

        map.on('mousemove', function(e) {
          // Autoscroll when doing or editing selection
          if ((drawStart || editStart) && lastMouseLatLng !== null) {
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;

            const bounds = map.getBounds();
            const cLatLng = map.getCenter();
            const zoom = map.getZoom();// keep the original zoom

            // mouse move toward east
            if (lng - lastMouseLatLng.lng > 0) {
              if (bounds.getEast() - lng < AUTOSCROLL_DISTANCE_TO_BORDER_LIMIT) {
                map.setView(L.latLng(cLatLng.lat, cLatLng.lng + AUTOSCROLL_OFFSET), zoom);
              }
            } else {
              // mouse move toward west
              if (lng - bounds.getWest() < AUTOSCROLL_DISTANCE_TO_BORDER_LIMIT) {
                map.setView(L.latLng(cLatLng.lat, cLatLng.lng - AUTOSCROLL_OFFSET), zoom);
              }
            }

            // mouse move toward south
            if (lat - lastMouseLatLng.lat > 0) {
              if (lat - bounds.getSouth() < AUTOSCROLL_DISTANCE_TO_BORDER_LIMIT) {
                map.setView(L.latLng(cLatLng.lat - AUTOSCROLL_OFFSET, cLatLng.lng), zoom);
              }
            } else {
              // mouse move toward north
              if (bounds.getNorth() - lat < AUTOSCROLL_DISTANCE_TO_BORDER_LIMIT) {
                map.setView(L.latLng(cLatLng.lat + AUTOSCROLL_OFFSET, cLatLng.lng), zoom);
              }
            }
          }
          lastMouseLatLng = e.latlng;
        });

        if (file) {
          var exportSelectionBtn = L.easyButton('fa-download',
            function(btn, map) {
              if (editStart === true || map.selectionLayer == null) {
                alert('有効な選択範囲はありませんでした。');
                return;
              }

              var data = drawnItems.toGeoJSON();
              if (map.selectionType === 'circle') {
                data.features[0].properties.radius = map.selectionLayer.getRadius();
              }
              data.features[0].properties.type = map.selectionType;

              var convertedData = 'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
              document.getElementById('selected_range_download').setAttribute('href', 'data:' + convertedData);
              document.getElementById('selected_range_download').setAttribute('download', 'SelectedRange.geojson');
              $('a#selected_range_download')[0].click();
            },
            '選択範囲をエクスポート'
          );

          $('input#selected_range_upload')[0].addEventListener('change', function(e) {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = L.Util.bind(function(e) {
              loadGeoJSON(e.target.result);
            }, this);
            if (file) {
              reader.readAsText(file);
            }
            this.value = '';
          }, false);

          var importSelectionBtn = L.easyButton('fa-upload',
            function(btn, map) {
              if (map.selectionLayer != null) {
                alert('選択範囲は一つしかできませんので、既存の選択範囲を削除してから新しい選択範囲をインポートしてください。');
                return;
              }

              $('input#selected_range_upload')[0].click();
            },
            '選択範囲をインポート'
          );

          L.easyBar([exportSelectionBtn, importSelectionBtn]).addTo(map);

          const loadGeoJSON = (content) => {
            try {
              if (typeof content !== 'string') {
                throw new Error();
              }
              content = JSON.parse(content);
            } catch (e) {
              alert('選択されたファイルは有効ファイルではありませんでした。');
              return;
            }

            const layers = L.geoJson(content, {
              onEachFeature: (feature, layer) => {
                if (feature.properties.type === 'polygon') {
                // 多角形
                  var rings = [];
                  for (var ring of feature.geometry.coordinates) {
                    var latlngs = [];
                    for (var point of ring) {
                      latlngs.push(L.latLng(point[1], point[0]));
                    }
                    latlngs.pop(); // geoJsonのPolygonでは、最後に先頭と同じ点が入ってくるので削る
                    rings.push(latlngs);
                  }
                  var handler = drawControl._toolbars.draw._modes.polygon.handler;
                  layer = new L.Polygon(rings, handler.options.shapeOptions);
                  layer.feature = feature;
                  L.Draw.Feature.prototype._fireCreatedEvent.call(handler, layer);
                  // value draw
                  const data = feature.geometry.coordinates;
                  searchBoundary = [];
                  for (let i = 0; i < data[0].length; i++) {
                    const lat = JSON.stringify(data[0][i][1]);
                    const lng = JSON.stringify(data[0][i][0]);
                    searchBoundary.push({ lat: lat, lon: lng });
                  }
                } else if (feature.properties.type === 'rectangle') {
                // 四角形
                  handler = drawControl._toolbars.draw._modes.rectangle.handler;
                  var corner1 = L.latLng(feature.geometry.coordinates[0][0][1], feature.geometry.coordinates[0][0][0]);
                  var corner2 = L.latLng(feature.geometry.coordinates[0][2][1], feature.geometry.coordinates[0][2][0]);
                  layer = new L.Rectangle(new L.LatLngBounds(corner1, corner2), handler.options.shapeOptions);
                  layer.feature = feature;
                  L.Draw.SimpleShape.prototype._fireCreatedEvent.call(handler, layer);
                  // value draw
                  const data = feature.geometry.coordinates;
                  searchBoundary = [];
                  for (let i = 0; i < data[0].length; i++) {
                    const lat = JSON.stringify(data[0][i][1]);
                    const lng = JSON.stringify(data[0][i][0]);
                    searchBoundary.push({ lat: lat, lon: lng });
                  }
                } else if (feature.properties.type === 'circle') {
                  // 円
                  var latlng = L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
                  handler = drawControl._toolbars.draw._modes.circle.handler;
                  var radius = parseFloat(feature.properties.radius); // " m"が勝手にとれるとは…
                  layer = new L.Circle(latlng, radius, handler.options.shapeOptions);
                  layer.feature = feature;
                  L.Draw.SimpleShape.prototype._fireCreatedEvent.call(handler, layer);
                  // value draw
                  const lat = JSON.stringify(feature.geometry.coordinates[1]);
                  const lng = JSON.stringify(feature.geometry.coordinates[0]);
                  const r = JSON.stringify(radius);
                  searchBoundary = [];
                  searchBoundary.push({ lat: lat, lon: lng });
                  _this.conditions.search_radius = r;
                }
                // start value draw
                _this.conditions.search_boundary = searchBoundary || null;
                _this.conditions.search_type = feature.properties.type || null;
                // end value draw

                if (layer) {
                  map.selectionType = feature.properties.selectionType;
                  map.selectionLayer = layer;
                  return layer;
                }
              }
            });

            if (layers.getLayers().length === 0) {
              alert('選択されたファイルに有効な選択範囲の情報はありませんでした。');
              return;
            }

            return layers;
          };
        }
        // 描画種類が１つしかないときは最初から選択状態に
        if (selectionTypes.length === 1) {
          this.fireDrawStart(map, selectionTypes[0]);
        }
      });
    },

    // type を選択状態にする
    fireDrawStart(map, type) {
      for (const i in map.control._toolbars) {
        const toolbar = map.control._toolbars[i];
        if (toolbar instanceof L.DrawToolbar) {
          toolbar._modes[type].handler.enable();
          break;
        }
      }
    },

    drawTranslations() {
      L.drawLocal.draw.toolbar.actions.title = '選択を取消';
      L.drawLocal.draw.toolbar.actions.text = '取消';
      L.drawLocal.draw.toolbar.finish.title = '選択完了';
      L.drawLocal.draw.toolbar.finish.text = '完了';
      L.drawLocal.draw.toolbar.undo.title = '最後のポイントを削除';
      L.drawLocal.draw.toolbar.undo.text = '削除';
      L.drawLocal.draw.toolbar.buttons.polygon = '多角形範囲を選択';
      L.drawLocal.draw.toolbar.buttons.rectangle = '矩形範囲を選択';
      L.drawLocal.draw.toolbar.buttons.circle = '円形範囲を選択';
      L.drawLocal.draw.handlers.polygon.tooltip.start = 'クリックして選択を開始';
      L.drawLocal.draw.handlers.polygon.tooltip.cont = 'クリックして選択を継続';
      var TOOLTIP_KEY_SCROLL = '十字キーでマップをスクロールすることが可能です。';
      L.drawLocal.draw.handlers.polygon.tooltip.end = '最初のポイントをクリックして選択を完了。<br>' + TOOLTIP_KEY_SCROLL;
      L.drawLocal.draw.handlers.circle.tooltip.start = 'クリックしてドラッグして円形範囲を選択';
      L.drawLocal.draw.handlers.rectangle.tooltip.start = 'クリックしてドラッグして 矩形範囲を選択';
      L.drawLocal.draw.handlers.simpleshape.tooltip.end = 'ドロップして、選択を完了。<br>' + TOOLTIP_KEY_SCROLL;
      // edit toolbar
      L.drawLocal.edit.toolbar.actions.save.title = '編集を確定';
      L.drawLocal.edit.toolbar.actions.save.text = '確定';
      L.drawLocal.edit.toolbar.actions.cancel.title = '編集を取消';
      L.drawLocal.edit.toolbar.actions.cancel.text = '取消';
      L.drawLocal.edit.toolbar.buttons.edit = '選択範囲を編集';
      L.drawLocal.edit.toolbar.buttons.editDisabled = '編集可能の選択範囲はありません';
      L.drawLocal.edit.toolbar.buttons.remove = '選択範囲を削除';
      L.drawLocal.edit.toolbar.buttons.removeDisabled = '削除可能の選択範囲はありません';
      L.drawLocal.edit.handlers.edit.tooltip.text = 'ポイントをドラッグして選択範囲を編集。<br>' + TOOLTIP_KEY_SCROLL;
      L.drawLocal.edit.handlers.edit.tooltip.subtext = '取消をクリックして編集を取消';
      L.drawLocal.edit.handlers.remove.tooltip.text = '選択範囲をクリックして選択範囲を削除';
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
      this.conditions.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
      this.conditions.center_lat = center.lat;
      this.conditions.center_lng = center.lng;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    onDrawOptionsChanged() {
      this.drawUsersTrace();
    },

    clearAllLayers() {
      this.drawItems.circles.forEach((circle) => {
        circle.remove();
      });
      this.drawItems.clusters.forEach((cluster) => {
        cluster.remove();
      });
      this.drawItems.paths.forEach((path) => {
        path.remove();
      });
      this.drawItems.turning_points.forEach((point) => {
        point.remove();
      });
      this.drawItems.flows.forEach((flow) => {
        flow.remove();
      });
    },

    consumeAndDraw(activities) {
      // Clear all marker before redraw
      this.clearAllLayers();

      this.usersData = _.chain(activities).groupBy('user_id').map((value, key) => ({
        user_id: key,
        activities: value
      })).value().filter(_ => !!_.activities);
      this.fitMapBounds(this.usersData);
      this.drawUsersTrace();
    },

    // MAP
    changeVisible(target, visible) {
      let targetArray;
      if (target === 'geo') {
        targetArray = this.drawOptions.clusters ? this.drawOptions.clusters : this.drawItems.circles;
        if (this.drawOptions.path) targetArray.concat(this.drawingCount.paths);
      } else if (target === 'clusters') {
        if (this.drawOptions.geo) {
          targetArray = visible ? this.drawItems.circles : this.drawItems.clusters;
          for (const c of targetArray) { this.map.removeLayer(c); }
          targetArray = visible ? this.drawItems.clusters : this.drawItems.circles;
          for (const c of targetArray) { c.addTo(this.map); }
        }
        return;
      } else { targetArray = this.drawItems[target]; }
      if (!targetArray) return;
      for (var i = 0; i < targetArray.length; i++) {
        if (visible) {
          targetArray[i].addTo(this.map);
        } else {
          this.map.removeLayer(targetArray[i]);
        }
      }
    },
    drawUsersTrace() {
      for (var i = 0; i < this.usersData.length; i++) {
        var userData = this.usersData[i];
        var userColor = d3.interpolateRainbow(((this.userColorIndex++) % this.usersData.length) / this.usersData.length);
        this.drawTraceData(userData.user_id, userData.activities, userColor);
      }
    },
    drawTraceData(userId, activities, userColor) {
      var userState = null;
      var drawingRecordCount = 0;

      var actPoints = [];
      const userClass = 'u' + this.userColorIndex;

      const numberOfSamples = 1000;
      activities = activities.filter(_ => !!_.track_points);
      for (var drawingI = 0; drawingI < activities.length; drawingI++) {
        var activity = activities[drawingI];
        for (var drawingJ = 0; drawingJ < activity.track_points.length; drawingJ++) {
          if (drawingRecordCount === numberOfSamples) {
            break;
          }
          var record = activity.track_points[drawingJ];
          record = MapHelper.normalizeTrackPoint(record);

          // Ignore location not in search boundary
          if (this.conditions.search_type === 'polygon') {
            if (!MapHelper.pointInPolygonNested([record.lat, record.lng], this.conditions.search_boundary.map((point) => [point.lat, point.lon]))) continue;
          } else if (this.conditions.search_type === 'circle') {
            if (!MapHelper.pointInCircle([record.lat, record.lng], [this.conditions.search_boundary[0].lat, this.conditions.search_boundary[0].lon], this.conditions.search_radius)) continue;
          }

          if (userState != null) {
            if (userState.act !== activity.type) {
              var prevLat = userState.lat;
              var prevLng = userState.lng;
              var theta = Math.atan2(record.lat - prevLat, record.lng - prevLng);
              var shrinkFactor = 600;
              var pl = L.polyline(
                [
                  [prevLat, prevLng],
                  [record.lat, record.lng],
                  [record.lat + Math.sin(theta + (3.0 / 4.0 * Math.PI)) / shrinkFactor,
                    record.lng + Math.cos(theta + (3.0 / 4.0 * Math.PI)) / shrinkFactor],
                  [record.lat + Math.sin(theta - (3.0 / 4.0 * Math.PI)) / shrinkFactor,
                    record.lng + Math.cos(theta - (3.0 / 4.0 * Math.PI)) / shrinkFactor],
                  [record.lat, record.lng]
                ],
                {
                  pane: 'circles',
                  color: '#ff0000',
                  weight: 2
                }
              );
              if (this.drawOptions.turning_points) {
                pl.addTo(this.map);
              }

              this.drawItems.turning_points.push(pl);

              this.draw(actPoints, userState.act, userColor);

              actPoints = [];
            }
          }

          var c;
          var radius;
          if (activity.type === 'stay') {
            radius = this.accuracy * 2.5 - 1;
            c = L.circleMarker(
              [record.lat, record.lng], {
                radius: radius,
                pane: 'circles',
                color: '#000',
                fillColor: '#fff',
                weight: 1,
                className: activity.type + ' ' + userClass
              }
            );
          } else {
            var color;
            if (activity.type === 'walk') {
              color = '#ffb100';
            } else if (activity.type === 'transport') {
              color = '#2a7fff';
            } else if (activity.type === 'bicycling') {
              color = '#067d1f';
            }
            radius = this.accuracy - 1;
            c = L.circleMarker(
              [record.lat, record.lng], {
                radius: radius,
                pane: 'circles',
                color: color,
                fillColor: color,
                weight: 1,
                className: activity.type + ' ' + userClass
              }
            );
          }

          var popupSentence = this.getCirclePopupData(userId, record.time, record.lat, record.lng, activity.type);
          c.bindPopup(popupSentence);
          c.on('click', function(e) {
            this.openPopup();
            // processProfilePane();
          });
          if (this.drawOptions.geo && !this.drawOptions.clusters) c.addTo(this.map);
          this.drawItems.circles.push(c);
          actPoints.push(c);

          userState = { act: activity.type, lat: record.lat, lng: record.lng };
          drawingRecordCount++;
          this.drawingCount++;
          if (activity.type === 'stay') {
            break;
          }
        }
      }

      if (actPoints.length > 0) { this.draw(actPoints, userState.act, userColor); }

      $('.' + userClass).hover(
        function() {
          $('.' + userClass).attr('fill-opacity', 1.0).attr('stroke-width', 2);
        },
        function() {
          $('.' + userClass).attr('fill-opacity', 0.2).attr('stroke-width', 1);
        }
      );
      return drawingRecordCount;
    },

    fitMapBounds(usersData) {
      var points = [];
      for (var i = 0, iLen = usersData.length; i < iLen; i++) {
        var userData = usersData[i];
        if (!userData.activities || userData.activities.length === 0) {
          continue;
        }

        if (userData.activities) {
          for (var j = 0, jLen = userData.activities.length; j < jLen; j++) {
            var trackPoints = userData.activities[j].track_points;
            if (trackPoints == null) {
              continue;
            }
            for (var k = 0, kLen = trackPoints.length; k < kLen; k++) {
              var p = trackPoints[k];
              if (typeof p.lon !== 'undefined') {
                points.push(L.point(p.lat, p.lon));
              } else if (typeof p.lng !== 'undefined') {
                points.push(L.point(p.lat, p.lng));
              }
            }
          }
        }
      }

      if (points.length > 0) {
        var trimedPoints = points.filter(function(elem) { return elem; });
        var bounds = L.bounds(trimedPoints);
        this.map.fitBounds(L.latLngBounds(
          L.latLng(bounds.min.x, bounds.min.y),
          L.latLng(bounds.max.x, bounds.max.y))
        );
      }
    },

    getCirclePopupData(userId, datetimeStr, lat, lon, activityType) {
      var resultSentence = '';

      // ユーザーID
      resultSentence += 'ユーザーID: ' + userId + '<br />';
      // 日時
      var userDatetime = moment(datetimeStr);
      var datetimeWord = userDatetime.format('YYYY-MM-DD HH:mm');
      resultSentence += '日時: ' + datetimeWord + '<br />';

      // 座標
      resultSentence += '座標: ' +
        ((lat === null) ? '' : parseFloat(lat).toFixed(12)) + ',' +
        ((lon === null) ? '' : parseFloat(lon).toFixed(12)) + '<br />';
      resultSentence += 'アクティビティ:' + this.activityTypes.find(_ => _.value === activityType).name + '<br />';
      return "<span class='user_id_or_index' style='display: none'>" + userId + '</span><p>' + resultSentence + '</p>';
    },

    draw(points, act, userColor) {
      const markers = L.markerClusterGroup({
        showCoverageOnHover: false,
        // spiderfyOnMaxZoom: true,
        // removeOutsideVisibleBounds: true,
        disableClusteringAtZoom: 18,
        maxClusterRadius: 50,
        iconCreateFunction: function(cluster) {
          return new L.DivIcon({ html: '<div style="background-color:rgba(' + userColor.substring(4, userColor.length - 1) + ', 0.3)"><span>' + cluster.getChildCount() + '</span></div>', className: 'marker-cluster ' + act, iconSize: new L.Point(40, 40) });
        }
      });
      for (const p of points) {
        markers.addLayer(p);
      }
      if (this.drawOptions.geo && this.drawOptions.clusters) markers.addTo(this.map);
      this.drawItems.clusters.push(markers);

      if (act === 'stay') {
        // DO nothing
      } else {
        let color;
        if (act === 'walk') {
          color = '#ffb100';
        } else if (act === 'transport') {
          color = '#2a7fff';
        } else if (act === 'bicycling') {
          color = '#067d1f';
        }
        const path = antPath(points.map(c => c.getLatLng()), { color: color, opacity: 0.6, delay: 1000, interactive: false, hardwareAccelerated: true });
        if (this.drawOptions.geo && this.drawOptions.paths) { path.addTo(this.map); }
        this.drawItems.paths.push(path);
      }
    },

    // Users
    selectionUser(user, index) {
      this.conditions.user_id_in.push(user.id);
      this.selectedUserIndexes.push(index);
      this.selectedUserIds.push(user.id);
    },

    deselectUser(user, index) {
      this.conditions.user_id_in.splice(this.conditions.user_id_in.indexOf(user.id), 1);
      this.selectedUserIndexes.splice(this.selectedUserIndexes.indexOf(index), 1);
      this.selectedUserIds.splice(this.selectedUserIds.indexOf(user.id), 1);
    },

    selectionAllUser() {
      this.conditions.user_id_in = this.users.map((_, i) => _.id);
      this.selectedUserIndexes = this.users.map((_, i) => i);
      this.selectedUserIds = this.users.map((_, i) => _.id);
    },

    unSelectAllUser() {
      this.conditions.user_id_in = [];
      this.selectedUserIndexes = [];
      this.selectedUserIds = [];
    },

    listSelectedUser() {
      return this.selectedUserIds.join(', ');
    },

    async exportActivityCsv() {
      this.statusExport = 'export_activity_csv';
      var q = {};
      let exportAll = false;
      const formData = Object.assign({}, this.conditions);
      if (this.ageConditionSelected === 'age') {
        delete formData.user_age_tier_in;
      } else if (this.ageConditionSelected === 'age_tier') {
        delete formData.user_age_gteq;
        delete formData.user_age_lteq;
      }
      if (this.ageSearchUnknown) {
        formData.user_age_null = true;
      }
      if (this.conditions.user_id_in.length < 1) {
        const slicedArray = this.users.filter((user, idx) => idx < 100);
        slicedArray.forEach(e => this.conditions.user_id_in.push(e.id));
        exportAll = true;
      }
      for (const [key, value] of Object.entries(formData)) {
        q[`q[${key}]`] = value;
      }
      const res = await this.exportActivity(q);
      const dataExport = res.data;
      const timeExport = moment.tz(new Date(), 'Asia/Tokyo').format();
      if (dataExport.length > 0) {
        let csvContent = 'data:text/csv;charset=Shift_JIS,';
        csvContent += [dataExport].join('\n').replace(/(^\[)|(\]$)/gm, '');
        const data = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', `Activity${timeExport}_.csv`);
        link.click();
      } else {
        alert('選択されたユーザーのデータがありません。');
      }
      if (exportAll === true) {
        this.conditions.user_id_in = [];
      }
      this.statusExport = null;
    },

    async exportLocationCsv() {
      this.statusExport = 'export_location_csv';
      var q = {};
      let exportAll = false;
      const formData = Object.assign({}, this.conditions);
      if (this.ageConditionSelected === 'age') {
        delete formData.user_age_tier_in;
      } else if (this.ageConditionSelected === 'age_tier') {
        delete formData.user_age_gteq;
        delete formData.user_age_lteq;
      }
      if (this.ageSearchUnknown) {
        formData.user_age_null = true;
      }
      if (this.conditions.user_id_in.length < 1) {
        const slicedArray = this.users.filter((user, idx) => idx < 100);
        slicedArray.forEach(e => this.conditions.user_id_in.push(e.id));
        exportAll = true;
      }
      for (const [key, value] of Object.entries(formData)) {
        q[`q[${key}]`] = value;
      }
      const res = await this.exportLocationActivity(q);
      const dataExport = res.data;
      const timeExport = moment.tz(new Date(), 'Asia/Tokyo').format();
      if (dataExport.length > 0) {
        let csvContent = 'data:text/csv;charset=Shift_JIS,';
        csvContent += [dataExport].join('\n').replace(/(^\[)|(\]$)/gm, '');
        const data = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', `Location_${timeExport}_.csv`);
        link.click();
      } else {
        alert('選択されたユーザーのデータがありません。');
      }
      if (exportAll === true) {
        this.conditions.user_id_in = [];
      }
      this.statusExport = null;
    },

    changeDrawCondition() {
      if (!this.conditionallyDraw) {
        alert('選択したユーザーを保持して描画、出力」をオフにした場合、選択ユーザーは無視され表示場所にいたユーザーを対象に描画、出力します。処理が時間かかる場合があります。');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .status-search {
    padding: 5px 10px;
  }

  .card {
    font-size: 12px !important;
  }

  .box-search-users {
    height: 50px;
    border: 1px solid gray;
    padding: 5px;
    .search-user {
      cursor: pointer;
    }
    .search-user:hover {
      color: #17a2b8;
    }
    .active-search-user {
      color: #17a2b8;
    }
    .w-90 {
      width: 90%;
    }
    .remove-user {
      cursor: pointer;
      color: #1f1f1f;
    }
  }

  .active-box-search-users {
    height: 250px;
    overflow: auto;
  }

  .box-search {
    height: 90vh;
    overflow: auto;
  }

  .box-search::-webkit-scrollbar {
    display: none;
  }

  .range-slider {
    width: 300px;
    margin: auto;
    text-align: center;
    position: relative;
    height: 3em;

    input[type=range] {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    input[type=range] {
      -webkit-appearance: none;
      width: 100%;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #17a2b8;
    }

    input[type=range]:focus::-ms-fill-lower {
      background: #17a2b8;
    }

    input[type=range]:focus::-ms-fill-upper {
      background: #17a2b8;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animation: 0.2s;
      background: #17a2b8;
      border-radius: 1px;
      box-shadow: none;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      z-index: 2;
      position: relative;
      box-shadow: 0px 0px 0px #000;
      border: 1px solid #17a2b8;
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: #BCF1FF;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
    }
  }

  ::v-deep{
    .leaflet-top, .leaflet-bottom {
      z-index: 998;
    }
  }
</style>
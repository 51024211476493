<template>
  <div class="btn-toggle">
    <label class="switch" :for="`enabledCheck${announcement.id}`">
      <input type="checkbox" :id="`enabledCheck${announcement.id}`" v-model="announcementData.enabled" @input="changeEnabled">
      <span class="slider round"></span>
    </label>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props: ['announcement', 'disable'],
  data() {
    return {
      announcementData: _.cloneDeep(this.announcement)
    };
  },
  methods: {
    ...mapActions('announcement', ['updateEnabled']),
    async changeEnabled() {
      const data = {
        id: this.announcementData.id,
        enabled: !this.announcementData.enabled
      };
      await this.updateEnabled(data);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
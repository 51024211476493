export const AgeConditions = [
  { name: '年代', value: 'age_tier' },
  { name: '年齢範囲', value: 'age' }
];

export const AgeTierOptions = [
  { name: '0-9', value: 'u_10' },
  { name: '10-19', value: 'u_20' },
  { name: '20-29', value: 'u_30' },
  { name: '30-39', value: 'u_40' },
  { name: '40-49', value: 'u_50' },
  { name: '50-59', value: 'u_60' },
  { name: '60-69', value: 'u_70' },
  { name: '70-79', value: 'u_80' },
  { name: '80以上', value: 'o_80' }
];

export const activityTypes = [
  { name: '徒歩', value: 'walk' },
  { name: '乗り物', value: 'transport' },
  { name: '自転車', value: 'bicycling' },
  { name: '滞在', value: 'stay' }
];

export const genders = [
  { name: '男性', value: 'male' },
  { name: '女性', value: 'female' },
  { name: '未登録を含む', value: 'unknown' }
];

export const CsvTypes = [
  { name: 'ユーザー情報', value: 'user_info' },
  { name: '活動量', value: 'mets' },
  { name: '歩数', value: 'steps' },
  { name: '自転車', value: 'distance_bicycle' },
  { name: '個人目標', value: 'user_target' },
  { name: '週23METs', value: 'target_23mets_per_week' },
  { name: '体重', value: 'weight' },
  { name: 'BMI', value: 'bmi' },
  { name: '検診', value: 'examination' },
  { name: 'ポイント', value: 'point' },
  { name: 'ウオークラリー', value: 'walk_rally' },
  { name: '運動３０分', value: 'exercise_gt_30mins' },
  { name: '中強度運動', value: 'medium_intensity_exercise' }
];
<template>
  <div>
    <div
      class="modal fade"
      id="modalCsvCaloTargetMaster"
      ref="vueModal"
      tabindex="-1" role="dialog"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-xl" role="document">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="modal-content">
            <div class="modal-header">
              <h5>目標カロリーの設定</h5>
              <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" @click="closeModal()" :disabled="['loading_preview', 'importing'].includes(status)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" :class="['preview', 'preview_error', 'importing', 'imported'].includes(status) ? 'modal-scroll-x' : ''">
              <div>
                <b-form-file
                  ref="csvFile"
                  browse-text="ファイルを選択"
                  accept="text/csv"
                  placeholder="CSVファイルを選択してください"
                  drop-placeholder="ここにファイルをドロップ。。。"
                  @change="onUploadFile"
                  v-model="dataImport"
                  :disabled="['loading_preview', 'importing'].includes(status)"
                ></b-form-file>
              </div>
              <div class="mt-2">
                <template v-for="(error, index) in errors">
                  <div :key="index" class="error-explanation"><template v-if="error.line">{{ error.line }}行目にエラーがありました:</template> {{ error.message }}</div>
                </template>
              </div>
              <div class="table-responsive" v-show="['preview', 'preview_error', 'importing', 'imported'].includes(status)">
                <table class="table table-bordered table-striped mt-3">
                  <thead>
                    <tr>
                      <th rowspan="2">No.</th>
                      <th class="mw-150 text-center"></th>
                      <th colspan="6" class="text-center">男性</th>
                      <th colspan="7" class="text-center">女性</th>
                    </tr>
                    <tr>
                      <th class="mw-100">身体活動</th>
                      <th class="mw-100">活動 (METs)</th>
                      <th class="mw-100">I (kcal)</th>
                      <th class="mw-100">活動 (METs)</th>
                      <th class="mw-100">II (kcal)</th>
                      <th class="mw-100">活動 (METs)</th>
                      <th class="mw-100">III (kcal)</th>
                      <th class="mw-100">活動 (METs)</th>
                      <th class="mw-100">I (kcal)</th>
                      <th class="mw-100">活動 (METs)</th>
                      <th class="mw-100">II (kcal)</th>
                      <th class="mw-100">活動 (METs)</th>
                      <th class="mw-100">III (kcal)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(caloTargetMaster, index) in caloTargetMasters">
                      <tr :key="index" :class="errors.some(error => error.line === index + 1) ? 'error-explanation' : ''">
                        <th class="font-weight-normal"> {{ index + 1 }} </th>
                        <th class="font-weight-normal">
                          <span v-if="caloTargetMaster.age_to">
                          {{ caloTargetMaster.age_from }} ~ {{ caloTargetMaster.age_to }}(歳)
                          </span>
                          <span v-else>{{ caloTargetMaster.age_from }}以上(歳)</span>
                        </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.m_mets_1 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.m_target_1 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.m_mets_2 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.m_target_2 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.m_mets_3 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.m_target_3 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.fm_mets_1 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.fm_target_1 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.fm_mets_2 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.fm_target_2 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.fm_mets_3 }} </th>
                        <th class="font-weight-normal"> {{ caloTargetMaster.fm_target_3 }} </th>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div v-show="status === 'loading_preview'" class="text-center p-5">
                <loading-spinner></loading-spinner>
              </div>
            </div>
            <div class="modal-footer d-flex">
              <div class="m-auto">
                <button class="btn btn-info mr-2"
                  :disabled="['preview_error', 'file_corrupted'].includes(status) || !status"
                  @click="handleSubmit(onPreviewCsv)"
                  v-if="['preview_error', 'picked_file', 'file_corrupted'].includes(status) || !status"
                >
                  <span v-if="status === 'loading_preview'">
                    <b-spinner small></b-spinner>
                  </span>
                  プレビュー
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  @click="['imported', 'importing'].includes(status) ? '' : onSubmit()"
                  :disabled="['imported', 'importing'].includes(status) || ($refs.csvFile && $refs.csvFile.files.length === 0)"
                  v-if="['preview', 'importing'].includes(status)"
                >
                  <span v-if="status === 'importing'">
                    <b-spinner small></b-spinner>
                  </span>
                  設定する
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      caloTargetMasters: [],
      errors: [],
      dataImport: null,
      status: null
    };
  },

  mounted() {
    $(this.$refs.vueModal).on('hidden.bs.modal', this.closeModal);
  },
  methods: {
    ...mapActions('caloTargetMaster', ['previewCsv', 'importCaloTargetMasters']),

    onUploadFile() {
      this.foodMasters = [];
      this.errors = [];
      this.status = 'picked_file';
    },

    async onPreviewCsv() {
      this.caloTargetMasters = [];
      this.errors = [];
      this.status = 'loading_preview';
      const response = await this.previewCsv(this.dataImport);
      this.$refs.csvFile.$refs.input.value = '';
      this.caloTargetMasters = response.data.rows;
      this.errors = response.data.errors;
      if (this.errors.length > 0 && this.errors[0].line) {
        this.status = 'preview_error';
      } else if (this.errors.length > 0 && !this.errors[0].line) {
        this.status = 'file_corrupted';
      } else {
        this.status = 'preview';
      }
    },

    async onSubmit(event) {
      this.status = 'importing';
      await this.importCaloTargetMasters(this.dataImport);
      this.status = 'imported';
      window.toastr.success('目標カロリーは変更しました。');
      setTimeout(() => {
        this.closeModal();
      }, 1000);
    },

    closeModal() {
      window.location.href = `${this.adminRootUrl}/calo_target_masters`;
    }
  }
};
</script>
<style lang="scss" scoped>
  .error-explanation {
    color: #a94442;
    font-size: 14px;
    font-weight: 700;
  }

  .modal-scroll-x {
    height: 75vh;
    overflow-x: auto;
  }

  .mw-100 {
    min-width: 100px;
  }

  .imported-message {
    font-size: 18px;
    font-weight: 700;
    color: #28a745;
    .failed-count {
      color: #a94442;
    }
  }
::v-deep {
  .modal-xl {
    max-width: 90vw;
  }
}
</style>
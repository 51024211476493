
import { API } from '../api';

export const state = {

};

export const mutations = {

};

export const getters = {

};

export const actions = {

  async previewCsv(context, file) {
    const formData = new FormData();
    formData.append('csv', file);
    try {
      return await API.post('/food_masters/import_preview', formData);
    } catch (error) {
      console.log(error);
    }
  },

  async importFoodMasters(context, file) {
    const formData = new FormData();
    formData.append('csv', file);

    try {
      return await API.post('/food_masters/import', formData);
    } catch (error) {
      console.log(error);
    }
  },

  async validateUnique(context, query) {
    try {
      return await API.post('/food_masters/validate_unique', query);
    } catch (error) {
      return error.response.data;
    }
  }

};
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[(_vm.check_point)?_c('h3',{staticClass:"card-title header-title"},[_vm._v("チェックポイント編集")]):_c('h3',{staticClass:"card-title header-title"},[_vm._v("チェックポイント新規作成")])]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"form",attrs:{"action":_vm.getAction(),"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('input',{attrs:{"type":"hidden","name":"authenticity_token"},domProps:{"value":_vm.csrfToken}}),_vm._v(" "),(_vm.check_point)?_c('input',{attrs:{"type":"hidden","name":"_method","value":"put"}}):_vm._e(),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"check_point[id]"},domProps:{"value":_vm.checkpointForm.id}}),_vm._v(" "),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"offset-xl-3 col-xl-6"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4"},[_vm._v("チェックポイント名"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"チェックポイント名","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkpointForm.name),expression:"checkpointForm.name"}],staticClass:"form-control",attrs:{"type":"text","name":"check_point[name]","placeholder":"ウォークラリー名を入力してください"},domProps:{"value":(_vm.checkpointForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.checkpointForm, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-2 justify-content-center"},[_c('label',{staticClass:"col-md-4"},[_vm._v("座標"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"flex-grow-1 d-flex align-self-end"},[_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{attrs:{"name":"緯度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex-grow-1 mr-1"},[_c('div',[_vm._v("緯度 "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkpointForm.lat),expression:"checkpointForm.lat"}],staticClass:"form-control",attrs:{"type":"text","name":"check_point[lat]"},domProps:{"value":(_vm.checkpointForm.lat)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.checkpointForm, "lat", $event.target.value)},_vm.parseData]}})])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"軽度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex-grow-1 mr-1"},[_c('div',[_vm._v("軽度 "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkpointForm.lng),expression:"checkpointForm.lng"}],staticClass:"form-control",attrs:{"type":"text","name":"check_point[lng]"},domProps:{"value":(_vm.checkpointForm.lng)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.checkpointForm, "lng", $event.target.value)},_vm.parseData]}})])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"btn btn-info mt-auto mb-0",attrs:{"data-toggle":"modal","data-target":"#locationPickerModal"}},[_c('i',{staticClass:"fas fa-globe-asia"})])],1)])])]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-2"},[_c('label',{staticClass:"col-md-4"},[_vm._v("付与ポイント"),_c('span',{staticClass:"label label-sm label-require"},[_vm._v("必須")])]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"付与ポイント","rules":"required|min:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkpointForm.point),expression:"checkpointForm.point"}],staticClass:"form-control",attrs:{"type":"number","min":"0","name":"check_point[point]"},domProps:{"value":(_vm.checkpointForm.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.checkpointForm, "point", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"offset-xl-3 col-xl-6"},[_c('ValidationProvider',{attrs:{"name":"アイコン","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),_c('div',[(_vm.icon)?_c('img',{staticClass:"icon",attrs:{"src":_vm.icon.image_url,"width":"100","height":"100"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"btn btn-info",attrs:{"data-toggle":"modal","data-target":"#iconPickerModal"}},[_vm._v("アイコンの設定")]),_vm._v(" "),(_vm.icon)?_c('input',{attrs:{"type":"hidden","name":"check_point[icon_id]","v-model":_vm.icon.id},domProps:{"value":_vm.icon.id}}):_vm._e()])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"card-footer text-center d-flex"},[_c('div',{staticClass:"btn btn-info mr-auto",on:{"click":function($event){return _vm.backToList()}}},[_vm._v("一覧へ戻る")]),_vm._v(" "),_c('submit-button',{staticClass:"mr-auto",attrs:{"object":"チェックポイント","action":_vm.check_point ? '更新' : '作成',"submitted":_vm.submitted,"disabled":invalid}})],1)])]}}])})],1)]),_vm._v(" "),_c('location-picker',{attrs:{"lat":_vm.checkpointForm.lat,"lng":_vm.checkpointForm.lng,"onUpdate":this.parseData()},on:{"update:lat":function($event){return _vm.$set(_vm.checkpointForm, "lat", $event)},"update:lng":function($event){return _vm.$set(_vm.checkpointForm, "lng", $event)}}}),_vm._v(" "),_c('icon-picker',{attrs:{"icons":_vm.icons,"icon":_vm.icon},on:{"update:icon":function($event){_vm.icon=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
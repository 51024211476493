<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title header-title">企業新規作成</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(onSubmit)" :action="getAction()" method="post">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <div class="card-body row">
            <div  class="offset-xl-3 col-xl-6">
              <div class="form-group row">
                <label class="col-md-4">企業名<span class="label label-sm label-require">必須</span></label>
                <div class="col-md-8">
                <ValidationProvider name="企業名" rules="required|max:255" v-slot="{ errors }">
                  <input type="text" class="form-control" name="company[name]" placeholder="企業名を入力してください" v-model="companyForm.name">
                  <span class="error-explanation">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-center d-flex">
            <div class="btn btn-info mr-auto" @click="backToList()">一覧へ戻る</div>
            <submit-button class="mr-auto" object="企業" :action="company ? '更新' : '作成'" :submitted="submitted"></submit-button>
          </div>
        </form>
        </ValidationObserver>
      </div>
    </div>
    <location-picker :lat.sync="companyForm.lat" :lng.sync="companyForm.lng"></location-picker>
    <icon-picker :icons="icons" :icon.sync="icon"></icon-picker>
  </div>
</template>

<script>
import Util from '@/utils/util.js';

export default {
  data() {
    return {
      adminRootUrl: process.env.VUE_APP_ADMIN_URL,
      csrfToken: Util.getCsrfToken(),
      companyForm: {
        name: null
      },
      submitted: false
    };
  },

  methods: {
    async onSubmit(e) {
      this.submitted = true;
      this.$refs.form.submit();
    },
    getAction() {
      return `${this.adminRootUrl}/companies`;
    },
    backToList() {
      window.location = `${this.adminRootUrl}/companies`;
    }
  }
};
</script>

<style>
  .icon {
    width: 100px;
    height: 100px;
  }
</style>